<template>
    <v-container fluid pa-0 v-if="zoneDetails != null && zoneDetails != undefined && Object.keys(zoneDetails) != 0">
        <ODValidationView v-if="zoneDetails.ODChargeUpfront == '0'">
        </ODValidationView>
        <ODView v-if="zoneDetails.ODChargeUpfront == '1'"></ODView>
        <v-dialog v-model="alertDialog" persistent max-width="290">
            <v-card class="pa-0">
                <v-card-title class="justify-center" style="position: relative">
                    <v-icon x-large color="red accent-2">mdi-alert-outline</v-icon>
                </v-card-title>
                <v-card-text class="text-center mt-5">
                    <span style="font-size: 20px">{{ alertMsg }}</span>
                </v-card-text>
                <v-card-actions class="text-center pb-6">
                    <v-container pa-0 fluid>
                        <v-row no-gutters>
                            <v-col cols="12">
                                <v-btn rounded block elevation="0" class="white--text exit_dialog_btn"
                                    @click="alertDialog = false">Close</v-btn>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-dialog v-model="persistentDialog" persistent max-width="290">
            <v-card class="pa-0 pb-5">
                <v-card-title class="justify-center" style="position: relative">
                    <v-icon x-large color="red accent-2">mdi-alert-outline</v-icon>
                </v-card-title>
                <v-card-text class="text-center mt-5">
                    <span style="font-size: 20px">{{ persistentMsg }}</span>
                </v-card-text>
            </v-card>
        </v-dialog>
    </v-container>
</template>
<script>
import { mapGetters } from "vuex";
import ODValidationView from "@/views/OnDemand/ODValidationView.vue"
import ODView from "@/views/OnDemand/ODView.vue"
import APIHelper from "../../apiHelper";
import { getURLParamValue } from "@/utils/util";
import { format } from "date-fns";
import { dateToTimeZoneDate } from "@/utils/formatDateTime";
import axios from 'axios';

export default {
    data: () => ({
        alertDialog: false,
        alertMsg: "",
        persistentDialog: false,
        persistentMsg: "",
    }),
    beforeRouteEnter(to, from, next) {
        next(async (vm) => {
            vm.$store.commit("SET_LOADING_STATUS", {
                property: "APP",
                pending: false,
            });
            vm.getMobileOperatingSystem() === 'iOS' ? vm.iosDevice = true : vm.iosDevice = false;
            if (from.path === "/ondemand/makepayment") {
                vm.contactObj =
                    vm.odDetails != null &&
                        Object.hasOwnProperty.call(vm.odDetails, "userDetail") &&
                        Object.hasOwnProperty.call(vm.odDetails?.userDetail, "contact") &&
                        vm.odDetails?.userDetail?.contact != undefined &&
                        vm.odDetails?.userDetail?.contact != null
                        ? Object.assign(vm.contactObj, {
                            countryCode:
                                vm.odDetails?.userDetail?.contact.slice(0, -10) == "+91"
                                    ? "IN"
                                    : vm.odDetails?.userDetail?.contact.slice(0, -10) == "+1"
                                        ? "US"
                                        : "",
                            phoneNumber: vm.odDetails?.userDetail?.contact,
                            formattedNumber: "+" + vm.odDetails.userDetail.contact,
                        })
                        : {};
                vm.licencePlate = vm.odDetails?.userDetail.plate
                    ? vm.odDetails?.userDetail.plate
                    : "";

            }
            let searchParams = window.location.search.split("=");
            let searchParamKey = searchParams.length > 0 ? searchParams[0] : "";
            let searchParamValue = searchParams.length > 0 ? searchParams[1] : "";
            if (window.location.href.includes("ondemand")) {
                vm.$store.commit('SET_TEMPLATE_FLAGS', 'ondemand-flag')
            }
            switch (searchParamKey) {
                case "?zcode": {
                    await vm.getZoneDetails(searchParamKey, searchParamValue);
                    break;
                }
            }
            if (vm.zoneDetails?.odAuthenticationFlow == 1 && !vm.checkOTPVerified) {
                let zcode = getURLParamValue("zcode");
                window.location.replace(window.location.origin + '?zcode=' + zcode + '#/ondemand/create')
            }
        });


    },
    computed: {
        ...mapGetters({
            zoneDetails: "getterZoneDetails",
            mySpotDetails: 'getterMySpotDetails',
        }),
        checkOTPVerified() {
            let odDetails = localStorage.getItem('odDetails');
            odDetails = (odDetails && typeof odDetails == "string") ? JSON.parse(odDetails) : {};
            return odDetails?.otpVerified
        },
    },
    components: {
        ODValidationView, ODView
    },
    methods: {
    /**
     * @method getZoneDetails fetch the zone details and commit SET_ZONE_DETAILS mutation.
     */
     async getZoneDetails(searchParamKey, searchParamValue) {
      try {
        this.$store.commit("SET_LOADING_STATUS", {
          property: "APP",
          pending: true,
        });
        var zoneDetails = await APIHelper(
          "GET",
          "/api/v1/user/config" + searchParamKey + "=" + searchParamValue
        );
        var odZoneDetails = await APIHelper(
          "GET",
          "/api/v1/getOnDemand/" + searchParamValue
        );
       
        this.$store.commit("SET_ZONE_DETAILS", zoneDetails.data?.zone);
        if (
          odZoneDetails?.data?.lot != undefined &&
          odZoneDetails?.data?.lot != null &&
          odZoneDetails?.data?.lot != ""
        ) {
          this.$store.commit(
            "SET_ON_DEMAND_ZONE_DETAILS",
            odZoneDetails.data?.lot
          );
        } else {
          this.persistentDialog = true;
          this.persistentMsg = odZoneDetails?.data?.message;
        }
        var getMySpotEvent = await APIHelper("POST", "/api/v1/getMySpotEvent", {
          zcode: zoneDetails?.data?.zone?.zcode,
        });
        const updatedEvents = await this.updateEventPrices(
          getMySpotEvent?.data?.events
        );
        this.$store.commit("SET_MYSPOT_DETAILS", updatedEvents);

       
      
        this.$store.commit("SET_LOADING_STATUS", {
          property: "APP",
          pending: false,
        });
      } catch (error) {
        this.$store.commit("SET_LOADING_STATUS", {
          property: "APP",
          pending: false,
        });

        console.log(error);
      }
    },
    async updateEventPrices(events) {
        var self =  this;
        let startDate = self.zoneDetails.timezone
            ? format(
              dateToTimeZoneDate(
                new Date(),
                self.zoneDetails.timezone
              ),
              "yyyy-MM-dd'T'HH:mm:ss"
            )
            : format(new Date(), "yyyy-MM-dd'T'HH:mm:ss");
      const fetchPromises = events.map((event) =>
        axios.post(process.env.VUE_APP_API_URL + "/api/v2/getRate", {
          eventId: event.eventId,
          startAt: startDate,
          stopAt: event.localEndDateTime.split(" ").join("T"),
          zcode: self.zoneDetails.zcode,
        })
      );
      try {
        const responses = await axios.all(fetchPromises);
        const results = responses.map((response) => response.data);
        const updatedEventPrices = results.map(a => a?.data)
        let updatedEvents = events;
        for(let i=0; i<updatedEvents.length; i++){
            let grandTotal = updatedEventPrices.find(p=> p?.eventId == updatedEvents[i]?.eventId)?.grandTotal || updatedEvents[i]?.price;
            Object.assign(updatedEvents[i], {price:grandTotal})
        }
        return updatedEvents;
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    },
  },
};
</script>