<template>
    <v-container fluid v-if="!loading" class="mb-16">
        <v-row class="text-center" no-gutters>
            <v-col cols="12">
                <v-img alt="PARKING.COM" class="my-3" height="42" contain src="@/assets/logo_huge_drk.png" />
            </v-col>
        </v-row>
        <v-row no-gutters>
            <v-col>
                <v-card class="mx-auto rounded-lg" max-width="374">
                    <v-dialog v-model="loading" persistent>
                        <v-card class="pa-4">
                            <v-progress-circular indeterminate color="red"></v-progress-circular>
                            <span class="ml-4" style="font-size: 14px">Please wait.</span>
                        </v-card>
                    </v-dialog>
                    <v-dialog v-model="odSessionLoading" persistent>
                        <v-card class="pa-4">
                            <v-progress-circular indeterminate color="red"></v-progress-circular>
                            <span class="ml-4" style="font-size: 14px">Processing your payment.</span>
                        </v-card>
                    </v-dialog>
                    <template slot="progress">
                        <v-progress-linear color="red" height="10" indeterminate></v-progress-linear>
                    </template>
                    <v-card  color="transparent" style="border-radius: 10px 10px 10px 10px;">
                        <v-img class="rounded-t-lg " height="auto" :src="zoneDetails.hasOwnProperty('image') &&
                            zoneDetails.image != '' &&
                            zoneDetails.image != null
                            ? zoneDetails.image
                            : zoneImgURL
                            " :lazy-src="zoneDetails.hasOwnProperty('image') &&
                                zoneDetails.image != '' &&
                                zoneDetails.image != null
                                ? zoneDetails.image
                                : zoneImgURL
                                " alt="Parking.com garage">
                            <v-row no-gutters>
                                <v-col>                            <v-overlay absolute></v-overlay>
                            </v-col>
                                <v-col cols="12">
                                    <p class="booking_header pt-3" style="color: white;position: relative;top: 0;margin-top: 45px;">Welcome to
                                        {{
                                            zoneDetails.hasOwnProperty("zoneName")
                                                ? zoneDetails.zoneName
                                                : ""
                                        }}
                                    </p>
                                </v-col>
                                <!-- <v-col cols="12" v-if="isPreAuthEnabled">
                                    <pre-auth-sheet style="position: absolute;bottom:0;z-index: 100;padding: 3px;"></pre-auth-sheet>

                                </v-col> -->
                            </v-row>


                            <template v-slot:placeholder>
                                <v-row class="fill-height ma-0" align="center" justify="center" no-gutters>
                                    <v-progress-circular indeterminate color="grey lighten-5"></v-progress-circular>
                                </v-row>
                            </template>

                        </v-img>
                    </v-card>
                    <v-card-text class="pa-0">
                        <v-form ref="detailsForm">
                            <v-container fluid pa-0>
                                <v-row no-gutters class="pt-2 information pa-0">
                                    <v-col cols="2" class="align-self-center">
                                        <v-img src="@/assets/list_divider_step.imageset/list_divider_step.png"
                                            width="45px" v-if="!infoStepperFlag">
                                            <div class="row text-center align-self-center pl-5">
                                                <div class="col-12 text-center">
                                                    <span style="font-size:large;font-weight:bold;color:white">1</span>
                                                </div>
                                            </div>
                                        </v-img>
                                        <v-img
                                            src="@/assets/list_divider_step_completed.imageset/list_divider_step_completed.png"
                                            width="45px" v-if="infoStepperFlag">
                                            <div class="row text-center pl-5">
                                                <div class="col-12 text-center">
                                                    <span style="font-size:larger;font-weight:bold;color:white">✓</span>
                                                </div>
                                            </div>
                                        </v-img>
                                    </v-col>
                                    <v-col cols="10" class="mt-3">
                                        <p style="color:#F2555C;font-weight:bolder">Your Information</p>
                                    </v-col>
                                </v-row>
                                <v-row no-gutters>
                                    <v-col cols="12" class="px-4  text-center color-black" style="font-size: 12px">
                                        <v-row no-gutters class="py-2">
                                            <v-col cols="1" class="text-right align-self-center">
                                                <v-icon class="material-icons-outlined">send_to_mobile</v-icon>
                                            </v-col>
                                            <v-col cols="11" class="px-2">
                                                <VuePhoneNumberInput v-model="contactObj.phoneNumber" size="md"
                                                    @update="onContactInput" show-code-on-list
                                                    :default-country-code="defaultCountryCode"
                                                    :color="contactOptions.color"
                                                    :valid-color="contactOptions.validColor"
                                                    :error-color="contactOptions.errorColor"
                                                    :border-radius="contactOptions.contactBorderRadius"
                                                    :error="!isContactValid" required
                                                    :translations="contactOptions.translations" />
                                            </v-col>
                                        </v-row>
                                        <v-row no-gutters class="py-2">
                                            <v-col cols="1" class="text-right align-self-center">
                                                <v-icon class="material-icons-outlined">pin</v-icon>
                                            </v-col>
                                            <v-col cols="11" class="px-2">
                                                <v-text-field class="pt-0 plate-region-label" v-model="licencePlate" hide-details="auto"
                                                    @change="rtdLocation ? getRTDData() : ''" maxlength="8"
                                                    :rules="[rules.alphaNumeric]" @keyup="uppercase"
                                                    label="License Plate *" clearable></v-text-field>
                                            </v-col>
                                        </v-row>
                                        <v-row no-gutters class="pl-4"
                                            v-if="rtdData != null && rtdData != undefined && rtdLocation">
                                            <v-col cols="1">
                                                <v-icon class="material-icons-outlined"
                                                    :color="rtdData.success ? 'white' : '#ffbc1f'">{{
                                                        rtdData.success
                                                            ? 'check_circle' : 'info' }}</v-icon>
                                            </v-col>
                                            <v-col class="white--text">
                                                {{ rtdData.success ? 'RTD in district pricing' : 'RTD out of district pricing'}}
                                            </v-col>
                                        </v-row>
                                        <v-row no-gutters class="pt-1">
                                            <v-spacer></v-spacer>
                                            <v-col cols="11" class="px-2 py-0 pb-3">
                                                <p class="text-left mb-0" style="font-size: 10px; line-height: 1.5">
                                                    Enter your full plate number to avoid citation. Max of
                                                    8 characters. Do not include spaces or special
                                                    characters.
                                                </p>
                                            </v-col>
                                        </v-row>
                                        <v-row no-gutters class="pa-2">
                                            <v-col cols="1" ></v-col>
                                            <v-col cols="5" class="pr-1">
                                                <v-select :disabled="!checkValidValue(licencePlate)" class="pt-0 plate-region-label" dense v-model="plateRegion.country" :items="countries" item-text="name" item-value="abbreviation" 
                                                    :menu-props="{ offsetY: true }"
                                                    hide-details label="Country *" @change="onCountryChange()">
                                                    <template v-slot:selection="data">
                              <v-row no-gutters>
                                <v-col cols="2" v-if="Object.hasOwnProperty.call(data, 'item') && Object.hasOwnProperty.call(data.item, 'abbreviation')">
                                  <vue-country-flag
                                :country="data.item.abbreviation"
                                class="flag"
                                size='small'
                              />
                                </v-col>
                                <v-col cols="10" class="country-name" v-if="Object.hasOwnProperty.call(data, 'item') && Object.hasOwnProperty.call(data.item, 'name')">
                                  <span class="px-2">{{
                                data.item.name
                              }}</span>
                                </v-col>
                              </v-row>
                            </template>

                            <template v-slot:item="data">
                              <template
                                v-if="Object.hasOwnProperty.call(data, 'item') && Object.hasOwnProperty.call(data.item, 'abbreviation') && Object.hasOwnProperty.call(data.item, 'name')"
                              >
                                <div class="d-flex align-center">
                                  <vue-country-flag
                                    :country="data.item.abbreviation"
                                    class="flag"
                                  />
                                  <span class="country-name px-2 pt-2">{{
                                    data.item.name
                                  }}</span>
                                </div>
                              </template>
                            </template>
                                                </v-select>
                                            </v-col>
                                            <v-col cols="6" class="pl-1">
                                                <v-select :disabled="!checkValidValue(licencePlate)" class="pt-0 plate-region-label" dense v-model="plateRegion.state" :items="countryStates" item-text="name" item-value="abbreviation" hide-details="auto"
                                                :rules="[rules.isRequired]" :label="plateRegion.country!='' ? plateRegion.country=='US' ? 'State *' : 'Province *' : 'State *'"
                                                ></v-select>
                                            </v-col>
                                        </v-row>
                                    </v-col>
                                </v-row>
                                <v-container fluid pa-0 v-if="eventStepperFlag">
                                    <v-row no-gutters class="pt-2 information pa-0">
                                        <v-col cols="2" class="align-self-center">
                                            <v-img src="@/assets/list_divider_step.imageset/list_divider_step.png"
                                                width="45px" v-if="selectedOption != 'event'">
                                                <div class="row text-center align-self-center pl-5">
                                                    <div class="col-12 text-center">
                                                        <span style="font-size:large;font-weight:bold;color:white"> {{ 2
                                                            }}</span>
                                                    </div>
                                                </div>
                                            </v-img>
                                            <v-img
                                                src="@/assets/list_divider_step_completed.imageset/list_divider_step_completed.png"
                                                width="45px" v-if="selectedOption == 'event'">
                                                <div class="row text-center pl-5">
                                                    <div class="col-12 text-center">
                                                        <span
                                                            style="font-size:larger;font-weight:bold;color:white">✓</span>
                                                    </div>
                                                </div>
                                            </v-img>
                                        </v-col>
                                        <v-col cols="10" class="mt-3">
                                            <p style="color:#F2555C;font-weight:bolder">Select An Event</p>
                                        </v-col>
                                    </v-row>
                                    <v-row no-gutters class="pa-4">
                                        <v-col cols="12">
                                            <v-select hide-details="auto" append-icon v-model="selectedEvent" solo
                                                label="Select An Event from the list of events"
                                                :outlined="selectedEvent != null && selectedEvent != '' && selectedEvent != undefined ? false : true"
                                                flat :items="mySpotDetails" @change="eventRateDateChange(selectedEvent)"
                                                class="no-border-select" item-text="eventName" item-value="item">
                                                <template slot="selection" slot-scope="data">
                                                    <!-- HTML that describe how select should render selected items -->
                                                    <v-row>
                                                        <v-col cols="8">
                                                            <p style="font-weight: 500;margin-bottom: -2px;font-size: 13px;"
                                                                class="pa-0"> {{
                                                                    data.item.eventName }} </p>
                                                            <p class="pa-0" style="font-size: 10px;">Exit by : <span
                                                                    style="font-weight: 700;">{{
                                                                        eventModeLocalTime(data.item.localEndDateTime)
                                                                    }}</span>
                                                            </p>
                                                        </v-col>
                                                        <v-col cols="4" class="mt-4 text-right">
                                                            <p style="color: #F2555C;font-weight: 500;font-size: 13px;">
                                                                $ {{
                                                                    data.item.price }}</p>
                                                        </v-col>
                                                    </v-row>
                                                </template>

                                                <template slot="item" slot-scope="data">
                                                    <!-- HTML that describe how select should render items when the select is open -->
                                                    <v-row>
                                                        <v-col cols="8">
                                                            <p style="font-weight: 500;margin-bottom: -2px;font-size: 13px;"
                                                                class="pa-0"> {{
                                                                    data.item.eventName }} </p>
                                                            <p class="pa-0" style="font-size: 10px;">Exit by : <span
                                                                    style="font-weight: 700;">{{
                                                                        eventModeLocalTime(data.item.localEndDateTime)
                                                                    }}</span>
                                                            </p>
                                                        </v-col>
                                                        <v-col cols="4" class="mt-4 text-right">
                                                            <p style="color: #F2555C;font-weight: 500;font-size: 13px;">
                                                                $ {{
                                                                    data.item.price }}</p>
                                                        </v-col>
                                                    </v-row>
                                                </template>
                                            </v-select>

                                        </v-col>
                                    </v-row>
                                </v-container>
                                <v-row no-gutters class="pt-2 information pa-0" v-if="!eventsOnlyLocation">
                                    <v-col cols="2" class="align-self-center">
                                        <v-img src="@/assets/list_divider_step.imageset/list_divider_step.png"
                                            width="45px"
                                            v-if="!timeStepperFlag || (selectedOption != 'date' && selectedOption != null)">
                                            <div class="row text-center align-self-center pl-5">
                                                <div class="col-12 text-center">
                                                    <span style="font-size:large;font-weight:bold;color:white">{{ 2
                                                        }}</span>
                                                </div>
                                            </div>
                                        </v-img>
                                        <v-img
                                            src="@/assets/list_divider_step_completed.imageset/list_divider_step_completed.png"
                                            width="45px" v-if="timeStepperFlag && selectedOption == 'date'">
                                            <div class="row text-center pl-5">
                                                <div class="col-12 text-center">
                                                    <span style="font-size:larger;font-weight:bold;color:white">✓</span>
                                                </div>
                                            </div>
                                        </v-img>
                                    </v-col>
                                    <v-col cols="10" class="mt-3">
                                        <p style="color:#F2555C;font-weight:bolder">{{ mySpotDetails != null &&
                                            mySpotDetails != '' && mySpotDetails != undefined && mySpotDetails.length >
                                            0 ?
                                            'Or ' : '' }} {{ 'Select your duration of stay' }}</p>
                                    </v-col>
                                </v-row>
                                <v-row no-gutters v-if="!eventsOnlyLocation">
                                    <v-col cols="12" class="px-4  text-center color-black" style="font-size: 12px">
                                        <v-row no-gutters class="pb-4">
                                            <v-col cols="1" class="text-right align-self-center">
                                                <v-icon class="material-icons-outlined">schedule</v-icon>
                                            </v-col>
                                            <v-col cols="11" class="px-3"
                                                v-if="zoneDetails.timePickerConfiguration == '0'">
                                                <v-text-field @click="openDatePicker()" readonly hide-details="auto"
                                                    placeholder="Select your duration of stay"
                                                    v-model="formattedTime"></v-text-field>
                                                <p class="black--text text-left pb-4"
                                                    v-if="Object.hasOwnProperty.call(zoneDetails, 'timePickerConfiguration') && zoneDetails.timePickerConfiguration == '0' && this.selectedOption == 'date'"
                                                    style="padding-bottom: 0 !important;margin-bottom: 0 !important  ;">
                                                    {{ zoneEndTimeLabel
                                                    }}</p>

                                            </v-col>
                                            <v-col cols="11" class="px-3"
                                                v-if="zoneDetails.timePickerConfiguration == '1'">
                                                <v-text-field @click="openSphereTimePicker()" readonly
                                                    hide-details="auto" placeholder="Select your duration of stay"
                                                    v-model="formattedTime"></v-text-field>
                                            </v-col>
                                        </v-row>
                                        <v-row class="text-left mt-0 px-2 py-0 pb-3" v-if="getTodaysHoursOfOperation">
                                            <v-col cols="1"></v-col>
                                            <v-col class="pt-0">
                                                <p style="
                                                   color: #1976d2;
                                            font-weight: bold;
                                            line-height: 16px;
                                                " class="ma-0">
                                                    HOURS OF OPERATION
                                                </p>
                                                <p class="ma-0">
                                                    Today :
                                                    {{
                                                        getTodaysHoursOfOperation
                                                            ? "Open 24 / 7"
                                                            : ""
                                                    }}
                                                </p>
                                            </v-col>
                                        </v-row>
                                    </v-col>
                                </v-row>
                                <v-row no-gutters class="pt-2 information pa-0"
                                    v-if="estimate != null && Object.keys(estimate).length != 0">
                                    <v-col cols="2" class="align-self-center">
                                        <v-img src="@/assets/list_divider_step.imageset/list_divider_step.png"
                                            width="45px" v-if="!paymentStepperFlag">
                                            <div class="row text-center align-self-center pl-5">
                                                <div class="col-12 text-center">
                                                    <span style="font-size:large;font-weight:bold;color:white">3</span>
                                                </div>
                                            </div>
                                        </v-img>
                                        <v-img
                                            src="@/assets/list_divider_step_completed.imageset/list_divider_step_completed.png"
                                            width="45px" v-if="paymentStepperFlag">
                                            <div class="row text-center pl-5">
                                                <div class="col-12 text-center">
                                                    <span style="font-size:larger;font-weight:bold;color:white">✓</span>
                                                </div>
                                            </div>
                                        </v-img>
                                    </v-col>
                                    <v-col cols="10" class="mt-3">
                                        <p style="color:#F2555C;font-weight:bolder">Your Parking Fees</p>
                                    </v-col>
                                </v-row>
                                <v-row no-gutters v-if="estimate != null && Object.keys(estimate).length != 0">
                                    <v-col cols="12" class="px-10 text-center " style="font-size: 12px">
                                        <v-row no-gutters class="pt-2 px-3">
                                            <v-col cols="4" class="text-left">
                                                <p style="font-size: small" class="black--text">
                                                    Parking
                                                </p>
                                            </v-col>
                                            <v-spacer></v-spacer>
                                            <v-col cols="4" class="text-left">
                                                <p style="font-size: small" class="black--text text-right">
                                                    {{ estimate.hasOwnProperty("quotedRegularPrice") ? "$ " +
                                                        estimate.quotedRegularPrice.toFixed(2)
                                                        : "-" }}

                                                </p>
                                            </v-col>
                                        </v-row>

                                        <v-row no-gutters class="pt-2 px-2">
                                            <v-col cols="4" class="text-left">
                                                <p style="font-size: small;color: #f2555c;font-weight: bold;">
                                                    Discount
                                                </p>
                                            </v-col>
                                            <v-spacer></v-spacer>
                                            <v-col cols="4" class="text-left">
                                                <p style="font-size: small;color: #f2555c;font-weight: bold;"
                                                    class=" text-right">
                                                    {{
                                                        estimate.hasOwnProperty('totalDiscount')
                                                            ? "( $ " +
                                                            Number(estimate.totalDiscount).toFixed(2) +
                                                            " )"
                                                            : "( - )"
                                                    }}
                                                </p>
                                            </v-col>
                                        </v-row>

                                        <v-row no-gutters class="mt-0 px-3">
                                            <v-col cols="4" class="text-left">
                                                <v-row no-gutters>
                                                    <v-col cols="11">
                                                        <p style="font-size: small" class="black--text">
                                                            Service Fee
                                                        </p>
                                                    </v-col>
                                                    <v-col cols="1">
                                                        <v-tooltip bottom v-model="show">
                                                            <template v-slot:activator="{ on, attrs }">
                                                                <v-icon class="material-icons-outlined" dark x-small
                                                                    color="primary" v-bind="attrs" @click="show = !show"
                                                                    v-on="on">info</v-icon>
                                                            </template>
                                                            <span>This fee helps cover the use of the technology that
                                                                provides a better parking
                                                                experience.</span>
                                                        </v-tooltip>
                                                    </v-col>

                                                </v-row>
                                            </v-col>
                                            <v-spacer></v-spacer>
                                            <v-col cols="4" class="text-left">
                                                <p style="font-size: small" class="black--text text-right">
                                                    {{ estimate.hasOwnProperty("totalFee") ? "$ " +
                                                        estimate.totalFee.toFixed(2) : "-" }}
                                                </p>
                                            </v-col>
                                        </v-row>
                                        <hr />
                                        <v-row no-gutters class="mt-2 px-3">
                                            <v-col cols="6" class="text-left">
                                                <p style="font-weight: bold;color:#F2555C;font-size:15px">
                                                    Purchase Total <sup>*</sup>
                                                </p>
                                            </v-col>
                                            <v-spacer></v-spacer>
                                            <v-col cols="4" class="text-left">
                                                <p style="font-size: small;font-weight: bold;color:#F2555C"
                                                    class=" text-right">
                                                    {{ estimate.hasOwnProperty("grandTotal") ? "$ " +
                                                        estimate.grandTotal.toFixed(2) : "-" }}

                                                </p>
                                            </v-col>
                                            <p>
                                                *Includes state and local taxes of {{
                                                    estimate.hasOwnProperty("totalTax") ?
                                                        "$ " +
                                                        estimate.totalTax.toFixed(2) : "-" }}
                                            </p>
                                        </v-row>
                                    </v-col>
                                </v-row>
                                <v-divider class="px-4"></v-divider>
                                <v-row no-gutters class="px-4 pt-4 pb-5 mb-10" v-if="!isAccessPromoCodeApplied">
                                    <v-col cols="12">
                                        <p style="margin-bottom: 0;font-weight: 500;">Promo/Access Code</p>
                                    </v-col>
                                    <v-col cols="12">
                                        <v-row no-gutters><v-col cols="8">
                                                <v-text-field outlined placeholder="code" v-model="accessPromoCode"
                                                    @keyup="accessPromoCodeUpperCase()" class="rounded-lg">
                                                </v-text-field>
                                            </v-col>
                                            <v-col cols="4" class="text-right">
                                                <v-btn class="white--text rounded-lg " style="text-transform: none;"
                                                    :disabled="!accessPromoCodeDisabled" color="#f2555c"
                                                    :loading="accessBtnLoading" @click="applyAccessPromoCode()"
                                                    x-large>Apply</v-btn>
                                            </v-col></v-row>

                                    </v-col>
                                </v-row>
                                <v-row no-gutters class="px-4 pt-4 pb-5" v-if="isAccessPromoCodeApplied">
                                    <v-col>
                                        <v-card style="background-color: #3EC6A51A;border: 0.75px solid #3EC6A5;">
                                            <v-card-text>
                                                <v-row no-gutters>
                                                    <v-col cols="1">
                                                        <v-icon class="material-icons-outlined text-left"
                                                            color="#3EC6A5">
                                                            done
                                                        </v-icon>
                                                    </v-col>
                                                    <v-col cols="8" class="text-left pl-4">
                                                        <p style="margin-bottom: 0;font-weight: 500;font-size: 18px;">{{
                                                            accessPromoCode }}
                                                        </p>
                                                    </v-col>
                                                    <v-col cols="3">
                                                        <v-btn color="#f2555c" text class="text-right"
                                                            @click="removeAccessPromoCode()" small>
                                                            REMOVE
                                                        </v-btn>
                                                    </v-col>
                                                </v-row>
                                            </v-card-text>
                                        </v-card>
                                    </v-col>
                                </v-row>
                            </v-container>
                        </v-form>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
        <SphereDateTimePicker :sheet="sheet"
            :maxParkingTime="OnDemandZoneDetails?.maxParkingTime || zoneDetails?.maxParkingTime"
            :timezone="zoneDetails.timezone">
        </SphereDateTimePicker>
        <!--<sphere-duration-picker  :sphereDurationPickerSheet="sphereDurationPickerSheet" :maxDurationTime="OnDemandZoneDetails.maxParkingTime"
      @close="sphereDurationPickerSheet = false">
        </sphere-duration-picker>
        -->
        <v-dialog v-model="dateDialog" max-width="350px">
            <v-date-picker color="#f2555c" v-model="selectedEndDate" :min="minDate" :max="maxDate"
                @change="setDateAndTime()">
            </v-date-picker>
        </v-dialog>
        <v-dialog v-model="alertDialog" persistent max-width="290">
            <v-card class="pa-0">
                <v-card-title class="justify-center" style="position: relative">
                    <v-icon x-large color="red accent-2">mdi-alert-outline</v-icon>
                </v-card-title>
                <v-card-text class="text-center mt-5">
                    <span style="font-size: 20px">{{ alertMsg }}</span>
                </v-card-text>
                <v-card-actions class="text-center pb-6">
                    <v-container pa-0 fluid>
                        <v-row no-gutters>
                            <v-col cols="12">
                                <v-btn rounded block elevation="0" class="white--text exit_dialog_btn"
                                    @click="alertDialog = false">Close</v-btn>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-footer fixed class="pl-0 pr-0 pb-0" app>
            <v-row no-gutters>
                <v-col cols="12" class="pt-0" style="display: flex;flex-direction: row;background: #0287D214;
                                            " v-if="userCardNumber != '' && checkOTPVerified">
                    <v-row no-gutters>
                        <v-col cols="6" class="text-left mt-2 pl-2">
                            <p>* {{ userCardNumber }} credit card on file</p>
                        </v-col>
                        <v-col cols="6" class="text-right  pr-2">
                            <v-btn @click="addPayment()" :disabled="disableSubmitBtn" color="#1a77d2" text
                                style="text-transform: none;;">Change</v-btn>
                            <v-icon class="material-icons-outlined align-self-center">arrow_right</v-icon>
                        </v-col>
                    </v-row>
                </v-col>
                <!-- If there is existing CC and if user wants to change -->
                <v-col cols="12" class="pl-2 pr-2">

                    <p class=" text-center mt-2" style="font-size:13px">Parking fees will be upfront</p>
                    <v-container fluid pa-0>
                        <v-row no-gutters>
                            <v-col :cols="paymentCols ? '12' : '6'" class="px-4"
                                v-if="userCardNumber != '' && checkOTPVerified">
                                <v-btn @click="createOdTransaction()" large rounded elevation="20" color="#F2555C"
                                    width="100%" style="text-transform:none;border-radius: 60px !important"
                                    class=" white--text " :disabled="disableSubmitBtn" :loading="odSessionLoading">
                                    <v-row no-gutters>
                                        <v-col cols="12" class="text-center">
                                            <p style="font-weight: bold;margin-bottom: 0;">Pay with CC</p>
                                        </v-col>
                                        <v-col cols="12" class="text-center">
                                            <p style="font-weight: 14px;margin-bottom: 0;"> (* {{ userCardNumber }})
                                            </p>
                                        </v-col>
                                    </v-row>

                                </v-btn>
                            </v-col>
                            <!-- If user wants to add new Card -->
                            <v-col :cols="paymentCols ? '12' : '6'" class="px-4 pb-3"
                                v-if="userCardNumber == '' || !checkOTPVerified">
                                <v-btn rounded elevation="20" large class=" white--text " color="#f2555c" width="100%"
                                    :disabled="disableSubmitBtn" :loading="addPaymentBtnLoading" @click="addPayment"
                                    style="text-transform:none;border-radius: 60px !;">Add Credit Card</v-btn>
                            </v-col>
                            <v-col cols="6" class="px-4 pb-3"
                                v-if="iosDevice && Object.hasOwnProperty.call(zoneDetails, 'paymentServices') && Object.hasOwnProperty.call(zoneDetails.paymentServices, 'apple_pay') && zoneDetails.paymentServices.apple_pay == '1'">
                                <!-- :ios-device="iosDevice && Object.hasOwnProperty.call(bookingDetails, 'booking') && Object.hasOwnProperty.call(bookingDetails.booking, 'paymentServices') && Object.hasOwnProperty.call(bookingDetails.booking.paymentServices, 'apple_pay') && bookingDetails.booking.paymentServices.apple_pay == '1'" -->
                                <apple-pay width='100%' height="43px" style="border-radius: 50px;" :pendingState="false"
                                    :amountRequired="estimate != null && estimate != undefined && estimate != '' && Object.hasOwnProperty.call(estimate, 'grandTotal') ? Number(estimate.grandTotal).toFixed(2) + '' : '1.00'"
                                    :disabled="disableSubmitBtn"
                                    :iosDevice="iosDevice && Object.hasOwnProperty.call(zoneDetails, 'paymentServices') && Object.hasOwnProperty.call(zoneDetails.paymentServices, 'apple_pay') && zoneDetails.paymentServices.apple_pay == '1'"
                                    :licensePlate="licencePlate" :contactObj="contactObj"
                                    :typeOfBooking="'OnDemand'"></apple-pay>
                            </v-col>
                            <!-- v-if="!iosDevice && Object.hasOwnProperty.call(bookingDetails, 'booking') && Object.hasOwnProperty.call(bookingDetails.booking, 'paymentServices') && Object.hasOwnProperty.call(bookingDetails.booking.paymentServices, 'google_pay') && bookingDetails.booking.paymentServices.google_pay == '1'" -->
                            <v-col cols="6" class="px-2 pb-3"
                                v-if="!iosDevice && Object.hasOwnProperty.call(zoneDetails, 'paymentServices') && Object.hasOwnProperty.call(zoneDetails.paymentServices, 'google_pay') && zoneDetails.paymentServices.google_pay == '1'">
                                <google-pay width='100%' height="43px" :disabled="disableSubmitBtn"
                                    :pendingState="false"
                                    :amountRequired="estimate != null && estimate != undefined && estimate != '' && Object.hasOwnProperty.call(estimate, 'grandTotal') ? Number(estimate.grandTotal).toFixed(2) + '' : '0.00'"
                                    :licensePlate="licencePlate" :typeOfBooking="'OnDemand'"
                                    :contactObj="contactObj"></google-pay>
                            </v-col>
                        </v-row>
                    </v-container>


                </v-col>
            </v-row>
        </v-footer>
    </v-container>
</template>

<script>
import { mapGetters } from "vuex";
// import PreAuthSheet from "@/uiComponents/PreAuthSheet.vue";
import SphereDateTimePicker from '@/uiComponents/SphereDateTimePicker.vue';
import GooglePay from '@/views/GooglePay.vue'
import ApplePay from "@/views/ApplePay.vue";
import { EventBus } from "@/lib/EventBus";
import { format, addDays, addHours, addMinutes } from "date-fns"; //isValid
import { dateToTimeZoneDate, format24TimeToAMPM } from "@/utils/formatDateTime";
import axios from "axios";
import APIHelper from "../../apiHelper";
import VuePhoneNumberInput from "vue-phone-number-input";
import "vue-phone-number-input/dist/vue-phone-number-input.css";
import rules from "@/utils/rules";
import VueCountryFlag from "vue-country-flag";
export default {
    data: (vm) => ({
        sphereDurationPickerSheet: false,
        accessBtnLoading: false,
        isAccessPromoCodeValid: null,
        isAccessPromoCodeApplied: false,
        accessPromoCode: "",
        selectedEndDate: "",
        dateDialog: false,
        sheet: false,
        rtdData: null,
        zoneEndTimeLabel: "",
        stopAt: vm.onDemandTime,
        showValToolTip: false,
        selectedOption: null,
        selectedEvent: null,
        userCardNumber: "",
        show: false,
        odSessionLoading: false,
        iosDevice: false,
        alertDialog: false,
        timeValue: null,
        alertMsg: "",
        stopTime: "",
        date: "",
        onDemandTime: "",
        minDate: null,
        maxDate: null,
        disableAddPaymentBtn: false,
        estimate: null,
        exitDate: null,
        rules: rules,
        isContactValid: true,
        licencePlate: "",
        contactObj: {},
        loading: false,
        contactOptions: {
            color: "#0288D1",
            validColor: "#0288D1",
            contactBorderRadius: 0,
            errorColor: "#FF5252",
            defaultCountry: "US",
            translations: {
                phoneNumberLabel: "Contact No",
            },
        },
        addPaymentBtnLoading: false,
    }),
    components: {
        VuePhoneNumberInput,
        GooglePay,
        ApplePay,
        SphereDateTimePicker,
        VueCountryFlag
        // PreAuthSheet 
    },
    computed: {
        isPreAuthEnabled(){
      return  Object.hasOwnProperty.call(this.zoneDetails , 'preAuth') && Object.hasOwnProperty.call(this.zoneDetails?.preAuth ,'enabled') &&this.zoneDetails?.preAuth?.enabled=='1'
    },
        paymentCols() {
            return (this.iosDevice && this.zoneDetails?.paymentServices?.apple_pay == '0') || (!this.iosDevice && this.zoneDetails?.paymentServices?.google_pay == '0')
        },
        accessPromoCodeDisabled() {
            return this.accessPromoCode != null && this.accessPromoCode != '' && this.accessPromoCode != undefined && this.onDemandTime != null && this.onDemandTime != undefined && this.onDemandTime != ""
        },
        rtdLocation() {
            return Object.hasOwnProperty.call(this.OnDemandZoneDetails, 'loyaltyPrograms') && this.OnDemandZoneDetails.loyaltyPrograms != undefined && this.OnDemandZoneDetails.loyaltyPrograms != null && this.OnDemandZoneDetails.loyaltyPrograms.length > 0 && this.OnDemandZoneDetails.loyaltyPrograms[0].name == "In District Parking (RTD)"
        },
        eventsOnlyLocation() {
            return this.zoneDetails.mySpotSettings.eventOnlyLocation == true
        },
        customEndTimeMinDate() {
            let currentTime = this.zoneDetails.timezone
                ?
                dateToTimeZoneDate(new Date(),
                    this.zoneDetails.timezone
                )
                : new Date();
            let givenTime = this.onDemandTime
            let timeVal = this.zoneDetails.customizedEndTime != null && this.zoneDetails.customizedEndTime != undefined ? this.zoneDetails.customizedEndTime.split(':') : ['23', '59', '59']
            givenTime.setHours(timeVal[0], timeVal[1], timeVal[2])
            let check = false;
            if (givenTime <= currentTime) {
                check = true;
                return check
            }
            else {
                check = false
                return check
            }
        },
        eventName() {
            return this.mySpotDetails[0] != null && this.mySpotDetails[0] != undefined && this.mySpotDetails[0] != "" ? this.mySpotDetails[0].eventName : "";
        },
        defaultCountryCode() {
            return Object.hasOwnProperty.call(this.contactObj, 'countryCode') && this.contactObj.countryCode != null && this.contactObj.countryCode != undefined && this.contactObj.countryCode != "" ? this.contactObj.countryCode : this.contactOptions?.defaultCountry
        },
        checkOTPVerified() {
            let odDetails = localStorage.getItem('odDetails');
            odDetails = odDetails ? JSON.parse(odDetails) : {};
            return odDetails?.otpVerified
        },
        formattedDateTime() {
            return this.onDemandTime !== null && this.onDemandTime !== undefined && this.onDemandTime !== "" ? format(this.onDemandTime, 'iii, LLL dd') : ""
        },
        formattedYear() {
            return this.onDemandTime !== null && this.onDemandTime !== undefined && this.onDemandTime !== "" ? format(this.onDemandTime, 'yyyy') : ""
        },
        ...mapGetters({
            odDetails: "getterOdDetails",
            casinoDetails: 'getterCasinoDetails',
            zoneDetails: "getterZoneDetails",
            templateFlags: 'getterTemplateFlags',
            mySpotDetails: 'getterMySpotDetails',
            OnDemandZoneDetails: 'getterOnDemandZoneDetails',
        }),
        formattedTime() {
            return this.onDemandTime !== null && this.onDemandTime !== undefined && this.onDemandTime !== "" ? (this.zoneDetails?.timePickerConfiguration == '0' ? format(this.onDemandTime, 'yyyy-MM-dd') : format(this.onDemandTime, 'yyyy-MM-dd hh:mm aa')) : ""
        },
        infoStepperFlag() {
            return this.contactObj == null ||
                !this.contactObj.isValid ||
                this.contactObj.phoneNumber == "" ||
                this.contactObj.phoneNumber == null ||
                this.licencePlate === "" ||
                this.licencePlate === null || 
                this.plateRegion?.country === "" ||
                this.plateRegion?.country === null || 
                this.plateRegion?.state === "" ||
                this.plateRegion?.state === null  ? false : true
        },
        eventStepperFlag() {
            return this.mySpotDetails != null && this.mySpotDetails != '' && this.mySpotDetails != undefined && this.mySpotDetails.length > 0
        },
        timeStepperFlag() {
            return this.onDemandTime === null || this.disableAddPaymentBtn || this.estimate === null || this.estimate === '' ||
                this.onDemandTime === "" || this.onDemandTime === undefined ? false : true;
        },
        paymentStepperFlag() {
            return this.estimate === null || this.estimate === '' || this.estimate.hasOwnProperty.call('grandTotal') || this.estimate.hasOwnProperty.call('quotedRegularPrice') || this.estimate.hasOwnProperty.call('totalFee') || this.estimate.hasOwnProperty.call('totalTax') ? false : true
        },
        dateTextFormat() {
            var event = new Date(this.exitDate);
            return event.toLocaleDateString("en-US", {
                year: "numeric",
                month: "short",
                day: "numeric",
            });
        },
        computedDateFormatted() {
            return this.onDemandTime != null && this.onDemandTime != "" && this.onDemandTime != undefined ? format(this.onDemandTime, 'MM/dd/yyyy') : ""
        },
        disableSubmitBtn() {
            let disabled =
                this.contactObj == null ||
                !this.contactObj.isValid ||
                this.contactObj.phoneNumber == "" ||
                this.contactObj.phoneNumber == null ||
                this.licencePlate === "" ||
                this.licencePlate === null ||
                /^[a-z0-9]+$/i.test(this.licencePlate) == false ||
                this.plateRegion?.country === "" ||
                this.plateRegion?.country === null || 
                this.plateRegion?.state === "" ||
                this.plateRegion?.state === null ||
                this.onDemandTime === null || this.onDemandTime === "" || this.onDemandTime === undefined || this.disableAddPaymentBtn || this.estimate === null || this.estimate === '';

            return disabled;
        },
    },
    watch: {
        infoStepperFlag() {
            if (this.infoStepperFlag) {
                this.findUser();
            }
        },
        // timeStepperFlag(){
        //     if(this.timeStepperFlag) {
        //         this.getRTDData();
        //     }
        // }
        // time(newVal, oldVal) {
        //     if (oldVal != null && newVal != null && oldVal != newVal && oldVal != "" && newVal != "" && (isValid(newVal))) {
        //         this.round(newVal);
        //     }
        // },
    },
    async mounted() {
        var self = this
        if (localStorage.getItem('odDetails')) {
            let odDetails = localStorage.getItem('odDetails');
            odDetails = odDetails ? JSON.parse(odDetails) : {};
            this.contactObj = odDetails?.contact ? Object.assign({}, odDetails.contact) : this.contactObj;
            this.licencePlate = odDetails?.licencePlate ? odDetails.licencePlate : this.licencePlate;
        }
        EventBus.$on("SQUARE_PAY_OD", async (tokenResult, verfiedToken, paymentType) => {
            await self.squarePay(tokenResult, verfiedToken, paymentType)
        })

        this.getMobileOperatingSystem() === 'iOS' ? this.iosDevice = true : this.iosDevice = false;
        this.setDefaultDate()
        EventBus.$on('EXIT_TIME_SELECTED', (duration) => {
            // this.onDemandTime = endTime;
            this.applyDuration(duration);
            this.isAccessPromoCodeApplied ? this.dateTimeChange('promoCode') : this.dateTimeChange();
            EventBus.$emit('SQUARE_ESTIMATE_CHANGE');
            this.sheet = false
        });
        

    },
    methods: {
        async squarePay(tokenResult, verfiedToken, paymentType) {
            try {
                var exitDateTime = ""
                if (this.selectedOption == 'event') {
                    var stopDateAndTime = this.selectedEvent.localEndDateTime !== null && this.selectedEvent.localEndDateTime !== undefined && this.selectedEvent.localEndDateTime !== "" ? this.selectedEvent.localEndDateTime.split(" ") : "";
                    var stopTime = stopDateAndTime != null && stopDateAndTime != undefined && stopDateAndTime != null && stopDateAndTime.length != 0 ? format24TimeToAMPM(stopDateAndTime[1]) : ""
                    var exitdate = this.selectedEvent.localEndDateTime !== null && this.selectedEvent.localEndDateTime !== undefined && this.selectedEvent.localEndDateTime !== "" && stopDateAndTime != null && stopDateAndTime != undefined && stopDateAndTime != null && stopDateAndTime.length != 0 ? stopDateAndTime[0] : "";
                    exitDateTime = exitdate + ' ' + stopTime;
                }
                else {
                    exitDateTime = this.onDemandTime != null || this.onDemandTime !== undefined || this.onDemandTime != "" ?
                        format(this.zoneDetails.timePickerConfiguration == '0' && this.customEndTimeMinDate ? addDays(this.onDemandTime, 1) : this.onDemandTime, 'yyyy-MM-dd')
                        +
                        " " +
                        (this.zoneDetails?.timePickerConfiguration == '0' ? format24TimeToAMPM(this.zoneDetails?.customizedEndTime) : format(this.onDemandTime, 'hh:mm a')) : ""
                }
                let entryDateTime = this.zoneDetails.timezone
                    ? format(
                        dateToTimeZoneDate(new Date(),
                            this.zoneDetails.timezone
                        ),
                        "yyyy-MM-dd hh:mm a"
                    )
                    : format(new Date(), "yyyy-MM-dd HH:mm a");
                this.$store.commit("SET_LOADING_STATUS", {
                    property: "APP",
                    pending: true,
                });
                let details = await APIHelper("POST", "/api/v1/prepaid/openTransaction", {
                    vehicleNo: this.licencePlate,
                    plateRegion:{
                        country:this.plateRegion?.country,
                        state:this.plateRegion?.state,
                    },
                    contact: this.contactObj.formattedNumber,
                    entryTime: entryDateTime,
                    exitTime: exitDateTime,
                    zcode: this.zoneDetails?.zcode,

                });
                if (details?.data?.status) {
                    this.odSessionLoading = true;
                    let postData = {
                        userDetail: {
                            eventId: this.selectedEvent?.eventId || "",
                            plate: this.licencePlate,
                            plateRegion:{
                                country:this.plateRegion?.country,
                                state:this.plateRegion?.state,
                            },
                            contact: this.contactObj?.formattedNumber,
                            exitTime: exitDateTime,
                            eventType: "OD",
                        },
                        cardDetail: {
                            userDevice: "2",
                            orderType: "3",
                            entry: [
                                {
                                    locationCode: this.zoneDetails?.zcode,
                                    startAt: entryDateTime,
                                    quantity: "1",
                                },
                            ],
                            paymentType: paymentType,
                            billingDetails: tokenResult?.details?.billing,
                            verification_token: verfiedToken,
                            source_id: tokenResult.token,
                            phoneNumber: this.contactObj?.formattedNumber,
                            email: tokenResult?.details?.shipping?.contact?.email || "",
                        },
                        mid: this.zoneDetails.mid,
                        source: "web",
                    };
                    if (this.codeType == "promoCode") {
                        Object.assign(postData, { promoCode: this.isAccessPromoCodeValid || null });
                    }
                    else if (this.codeType == 'accessCode') {
                        Object.assign(postData, { accessCode: this.isAccessPromoCodeValid || null });
                    }
                    var odAddCard = await APIHelper(
                        "POST",
                        "/api/v1/prepaid/addCard",
                        postData
                    );
                    if (odAddCard?.data?.status == true) {
                        this.$store.commit("SET_LOADING_STATUS", {
                            property: "APP",
                            pending: false,
                        });
                        //url segment is app mixin computed property
                        let url =
                            window.location.origin + '/g/' + odAddCard?.data?.data?.bid;
                        window.location.replace(url);
                    } else if (odAddCard?.data?.status == false) {
                        this.$store.commit("SET_LOADING_STATUS", {
                            property: "APP",
                            pending: false,
                        });
                        this.alertDialog = true;
                        this.alertMsg = odAddCard?.data?.message;
                    }
                    this.odSessionLoading = false;
                    this.$store.commit("SET_LOADING_STATUS", {
                        property: "APP",
                        pending: false,
                    });
                }
                else {
                    this.$store.commit("SET_LOADING_STATUS", {
                        property: "APP",
                        pending: false,
                    });
                    this.odSessionLoading = false;
                    this.alertMsg = details?.data?.message ? details?.data?.message : "";
                    this.alertDialog = true;
                    if (details?.data?.message == 'Booking Already Exists') {
                        //url segment is app mixin computed property
                        let url = window.location.origin + '/g/' + details?.data?.data?.refno
                        window.location.replace(url);

                    }
                }
            } catch (error) {
                this.$store.commit("SET_LOADING_STATUS", {
                    property: "APP",
                    pending: false,
                });
                this.odSessionLoading = false;
                console.log(error);
            }
        },
        /**
         * @method applyDuration - applies the duration selected from duration picker to the current time.
         * @param {*} duration - format {day:   , hour:selectedHour, minute:selectedMinute}
         */
        applyDuration(duration) {
            let endTime = this.zoneDetails.timezone ? dateToTimeZoneDate(new Date(), this.zoneDetails?.timezone) : new Date();
            let addedDays = addDays(endTime, duration.day);
            let addedhours = addHours(addedDays, duration.hour);
            let addedMinutes = addMinutes(addedhours, Number(duration.minute));
            this.onDemandTime = addedMinutes;
        },
        openSphereDurationPicker() {
            EventBus.$emit('open-duration-picker')
        },
        /**
         * 
           * @method getZoneDetails fetch the zone details and commit SET_ZONE_DETAILS mutation.
           */
        async getZoneDetails(searchParamKey, searchParamValue) {
            this.loading = true
            try {
                var zoneDetails = await APIHelper(
                    "GET",
                    "/api/v1/user/config" + searchParamKey + "=" + searchParamValue
                );
                this.loading = false
                var getMySpotEvent = await APIHelper("POST", "/api/v1/getMySpotEvent", { zcode: zoneDetails?.data?.zone?.zcode })
                const updatedEvents = await this.updateEventPrices(
                    getMySpotEvent?.data?.events
                );
                this.$store.commit("SET_ZONE_DETAILS", zoneDetails.data?.zone);
                this.$store.commit("SET_MYSPOT_DETAILS", updatedEvents);
                this.findUser()
            } catch (error) {
                this.loading = false
                console.log(error);
            }
        },
        async updateEventPrices(events) {
        var self =  this;
        let startDate = self.zoneDetails.timezone
            ? format(
              dateToTimeZoneDate(
                new Date(),
                self.zoneDetails.timezone
              ),
              "yyyy-MM-dd'T'HH:mm:ss"
            )
            : format(new Date(), "yyyy-MM-dd'T'HH:mm:ss");
      const fetchPromises = events.map((event) =>
        axios.post(process.env.VUE_APP_API_URL + "/api/v2/getRate", {
          eventId: event.eventId,
          startAt: startDate,
          stopAt: event.localEndDateTime.split(" ").join("T"),
          zcode: self.zoneDetails.zcode,
        })
      );
      try {
        const responses = await axios.all(fetchPromises);
        const results = responses.map((response) => response.data);
        const updatedEventPrices = results.map(a => a?.data)
        let updatedEvents = events;
        for(let i=0; i<updatedEvents.length; i++){
            let grandTotal = updatedEventPrices.find(p=> p?.eventId == updatedEvents[i]?.eventId)?.grandTotal || updatedEvents[i]?.price;
            Object.assign(updatedEvents[i], {price:grandTotal})
        }
        return updatedEvents;
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    },
        async applyAccessPromoCode() {
            this.accessBtnLoading = true;
            await this.dateTimeChange('promoCode')
            this.accessBtnLoading = false
        },
        removeAccessPromoCode() {
            this.dateTimeChange()
            this.isAccessPromoCodeValid = false
            this.isAccessPromoCodeApplied = false;
            this.accessPromoCode = null;
        },
        setDateAndTime() {
            let exitDateAndTime = this.selectedEndDate + " " + this.zoneDetails?.customizedEndTime

            this.onDemandTime = new Date(exitDateAndTime)
            this.dateTimeChange()

        },

        openDatePicker() {
            this.dateDialog = true;

        },
        setDefaultDate() {
            this.onDemandTime = Object.hasOwnProperty.call(this.zoneDetails, 'timezone') && this.zoneDetails.timezone
                ?
                addMinutes(dateToTimeZoneDate(new Date(),
                    this.zoneDetails.timezone) , 30)
                : addMinutes(new Date(),30)
            this.minDate = this.zoneDetails.timezone
                ?
                addMinutes(dateToTimeZoneDate(new Date(),
                    this.zoneDetails.timezone),30)
                : addMinutes(new Date() ,30)
            this.minDate = format(this.minDate, 'yyyy-MM-dd');
            //calculate max parkign time in seconds
            let maxParkingTime =
                this.OnDemandZoneDetails != null
                    ? Number(this.OnDemandZoneDetails?.maxParkingTime?.days) *
                    24 *
                    60 *
                    60 +
                    Number(this.OnDemandZoneDetails?.maxParkingTime?.hours) * 60 * 60 +
                    Number(this.OnDemandZoneDetails?.maxParkingTime?.minutes) * 60
                    : null;
            let currentDateTime = new Date().getTime();

            //get max date in seconds based on max parking time
            let maxZoneDate =
                maxParkingTime != null ? currentDateTime + maxParkingTime * 1000 : null;
            //get max date with time and set it to maxDateTime
            this.maxDate = this.zoneDetails.timezone
                ? format(
                    dateToTimeZoneDate(
                        new Date(maxZoneDate),
                        this.zoneDetails.timezone
                    ),
                    "yyyy-MM-dd"
                )
                : format(new Date(), "yyyy-MM-dd");
            this.dateTimeChange() 
        },
        async getRTDData() {
            try {
                this.$store.commit("SET_LOADING_STATUS", {
                    property: "APP",
                    pending: true,
                });
                const response = await axios.get(`https://parking.com/on-demand/getAccessCodeByLicensePlate/${this.licencePlate}.json`);
                this.rtdData = response.data
                this.$store.commit("SET_LOADING_STATUS", {
                    property: "APP",
                    pending: false,
                });
            }
            catch (error) {
                this.$store.commit("SET_LOADING_STATUS", {
                    property: "APP",
                    pending: false,
                });
                console.log(error)
            }
        },
        // Get Rates call in accordance with Time Picker Configuration
        async dateTimeChange(codeType) {
            try {
                this.$store.commit("SET_LOADING_STATUS", {
                    property: "APP",
                    pending: true,
                });
                this.startDate = this.zoneDetails.timezone
                    ? format(
                        dateToTimeZoneDate(
                            new Date(),
                            this.zoneDetails.timezone
                        ),
                        "yyyy-MM-dd'T'HH:mm:ss"
                    )
                    : format(new Date(), "yyyy-MM-dd'T'HH:mm:ss");
                let stopTime = Object.hasOwnProperty.call(this.zoneDetails, 'timePickerConfiguration') && this.zoneDetails.timePickerConfiguration == '0' ? (this.zoneDetails?.customizedEndTime ? this.zoneDetails?.customizedEndTime : '23:59:00') : format(this.onDemandTime, 'HH:mm:ss'); //?.hh+":"+this.exitTimeSelected.mm+":00"//format(this.checkoutDate, "yyyy-MM-dd'T'HH:mm:ss")
                let exitdate = this.onDemandTime !== null && this.onDemandTime !== undefined && this.onDemandTime !== "" ? format(this.zoneDetails.timePickerConfiguration == '0' && this.customEndTimeMinDate ? addDays(this.onDemandTime, 1) : this.onDemandTime, 'yyyy-MM-dd') : "";
                let stopDate = exitdate + 'T' + stopTime;
                let data = {
                    // orderType: '2',
                    startAt: this.startDate,
                    stopAt: stopDate,
                    zcode: this.zoneDetails.zcode,
                };
                if (codeType == "promoCode") {
                    Object.assign(data, { promoCode: this.accessPromoCode || null });
                }
                else if (codeType == 'accessCode') {
                    Object.assign(data, { accessCode: this.accessPromoCode || null });
                }
                this.stopAt = new Date(stopDate)
                this.addPaymentBtnLoading = true;
                var getRate = await APIHelper("POST", "/api/v2/getRate", data);
                //condition to check if the date time selected is lesser than current time
                if (!getRate?.data?.status) {
                    if (codeType == 'promoCode' && !getRate?.data?.status) {
                        this.dateTimeChange('accessCode')
                    }
                    else {
                        this.alertDialog = true;
                        this.alertMsg = getRate?.data?.message || "Error in getting rate details";
                        this.disableAddPaymentBtn = false;
                        this.addPaymentBtnLoading = false;


                    }

                }
                else {
                    if (codeType == 'promoCode' && getRate?.data?.status) {
                        this.isAccessPromoCodeApplied = true;
                        this.isAccessPromoCodeValid = this.accessPromoCode
                        this.codeType = codeType;

                    }

                    else if (codeType == 'accessCode' && getRate?.data?.status ) {
                        this.isAccessPromoCodeValid = this.accessPromoCode
                        this.isAccessPromoCodeApplied = true;
                        this.codeType = codeType;

                    }
                    this.zoneEndTimeLabel = this.zoneDetails?.timePickerConfiguration == '0' ? `You are eligible to stay till ${exitdate}` : ""
                    this.estimate = Object.assign({}, getRate?.data?.data);
                    EventBus.$emit('SQUARE_ESTIMATE_CHANGE')
                    this.selectedEvent = ""
                    this.addPaymentBtnLoading = false;
                    this.selectedOption = 'date'
                    this.disableAddPaymentBtn = false;
                    this.dateDialog = false;
                }
                this.$store.commit("SET_LOADING_STATUS", {
                    property: "APP",
                    pending: false,
                });

            } catch (error) {
                this.dateDialog = false;
                this.addPaymentBtnLoading = false;
                console.log(error);
            }
        },
        openSphereTimePicker() {
            // this.setDefaultDate()
            EventBus.$emit('open-timepicker')
            this.sheet = true
        },
        async eventRateDateChange(event) {
            let selectedEvent = this.mySpotDetails.find((a) => a.eventName == event)
            this.selectedEvent = selectedEvent
            try {
                //format(new Date(this.checkinDate), "yyyy-MM-dd'T'HH:mm:ss");
                // this.setTimePicker();
                if (selectedEvent != null && selectedEvent != undefined && selectedEvent != '') {
                    this.startDate = this.zoneDetails.timezone
                        ? format(
                            dateToTimeZoneDate(
                                new Date(),
                                this.zoneDetails.timezone
                            ),
                            "yyyy-MM-dd'T'HH:mm:ss"
                        )
                        : format(new Date(), "yyyy-MM-dd'T'HH:mm:ss");
                    let stopDateAndTime = selectedEvent.localEndDateTime !== null && selectedEvent.localEndDateTime !== undefined && selectedEvent.localEndDateTime !== "" ? selectedEvent.localEndDateTime.split(" ") : "";
                    let stopTime = stopDateAndTime != null && stopDateAndTime != undefined && stopDateAndTime != null && stopDateAndTime.length != 0 ? stopDateAndTime[1] : ""
                    let exitdate = selectedEvent.localEndDateTime !== null && selectedEvent.localEndDateTime !== undefined && selectedEvent.localEndDateTime !== "" && stopDateAndTime != null && stopDateAndTime != undefined && stopDateAndTime != null && stopDateAndTime.length != 0 ? stopDateAndTime[0] : "";
                    let stopDate = exitdate + 'T' + stopTime;
                    let data = {
                        startAt: this.startDate,
                        stopAt: stopDate,
                        zcode: this.zoneDetails.zcode,
                        eventId: this.selectedEvent?.eventId || ""

                    };
                    if (this.codeType == "promoCode") {
                        Object.assign(commitData, { promoCode: this.isAccessPromoCodeValid || null });
                    }
                    else if (this.codeType == 'accessCode') {
                        Object.assign(commitData, { accessCode: this.isAccessPromoCodeValid || null });
                    }
                    this.stopAt = new Date(stopDate)
                    this.addPaymentBtnLoading = true;
                    var getRate = await APIHelper("POST", "/api/v2/getRate", data);
                    //condition to check if the date time selected is lesser than current time
                    if (!getRate?.data?.status) {
                        this.disableAddPaymentBtn = true;
                        this.alertDialog = true;
                        this.alertMsg = getRate?.data?.message || "Error in getting rate details";

                    }
                    else {
                        this.disableAddPaymentBtn = false;
                        this.onDemandTime = new Date(selectedEvent.localEndDateTime)
                    }
                    this.estimate = Object.assign({}, getRate?.data?.data);
                    EventBus.$emit('SQUARE_ESTIMATE_CHANGE')
                    this.selectedOption = 'event'
                    let exitDateTime = selectedEvent.localEndDateTime
                    let entryDateTime = this.zoneDetails.timezone
                        ? format(
                            dateToTimeZoneDate(
                                new Date(),
                                this.zoneDetails.timezone
                            ),
                            "yyyy-MM-dd HH:mm:ss"
                        )
                        : format(new Date(), "yyyy-MM-dd HH:mm:ss");
                    let commitData = {
                        plate: this.licencePlate,
                        plateRegion:{
                            country:this.plateRegion?.country,
                            state:this.plateRegion?.state,
                        },
                        contact: this.contactObj.formattedNumber,
                        entryTime: entryDateTime,
                        exitTime: exitDateTime,
                        estimate: this.estimate,
                        eventId: this.selectedEvent?.eventId || '',
                        accessCode: this.accessPromoCode || null

                    };
                    this.$store.commit("SET_ONDEMAND_USERDETAILS", commitData);
                    // estimatedVal != "" &&
                    // estimatedVal != null &&
                    // estimatedVal != undefined
                    //   ? Number(estimatedVal).toFixed(2)
                    //   : estimatedVal;
                    this.addPaymentBtnLoading = false;
                }
            } catch (error) {
                this.addPaymentBtnLoading = false;
                console.log(error);
            }
        },
        eventModeLocalTime(time) {
            return time != null && time != undefined && time != "" ? format(new Date(time), `LLL dd , yyyy`) + " at " + format(new Date(time), `hh:mm a`) : "";

        },
        async findUser() {
            try {
                var findUser = await APIHelper("POST", "/api/v1/findUserWithCC", {
                    zid: this.zoneDetails?.zid,
                    contact: this.contactObj?.formattedNumber
                });
                if (findUser != null && findUser != undefined && findUser != "" && findUser?.data?.status) {
                    this.userCardNumber = findUser?.data?.userSavedCardDetail?.paymentCardNo
                }
                else {
                    this.userCardNumber = ''
                }
            }
            catch (error) {
                console.log(error)
            }
        },
        async createOdTransaction() {
            //  let phoneNumber = this.contactObj.formattedNumber.substring(1, this.contactObj.formattedNumber.length)
            this.odSessionLoading = true;
            let exitDateTime = format(this.onDemandTime, 'yyyy-MM-dd')
                +
                " " +
                (format(this.onDemandTime, 'hh:mm a'))
            let entryDateTime = this.zoneDetails.timezone
                ? format(
                    dateToTimeZoneDate(new Date(),
                        this.zoneDetails.timezone
                    ),
                    "yyyy-MM-dd hh:mm a"
                )
                : format(new Date(), "yyyy-MM-dd'T'HH:mm a");
            var findUser = await APIHelper("POST", "/api/v1/findUserWithCC", {
                zid: this.zoneDetails?.zid,
                contact: this.contactObj?.formattedNumber
            });
            let details = await APIHelper("POST", "/api/v1/prepaid/openTransaction", {
                vehicleNo: this.licencePlate,
                plateRegion:{
                    country:this.plateRegion?.country,
                    state:this.plateRegion?.state,
                },
                contact: this.contactObj.formattedNumber,
                entryTime: entryDateTime,
                exitTime: exitDateTime,
                zcode: this.zoneDetails?.zcode,
            });
            if (details?.data?.status) {
                if (findUser != null && findUser != undefined && findUser != "" && findUser?.data?.status) {
                    try {
                        let postData = {
                            userDetail: {
                                plate: this.licencePlate,
                                plateRegion:{
                                    country:this.plateRegion?.country,
                                    state:this.plateRegion?.state,
                                },
                                contact: this.contactObj.formattedNumber,
                                exitTime: exitDateTime,
                                eventType: "OD",
                                eventId: this.selectedEvent?.eventId || ''
                            },
                            cardDetail: {
                                userDevice: "2",
                                orderType: "2",
                                paymentType: "CREDIT_CARD",
                                entry: [
                                    {
                                        locationCode: this.zoneDetails?.zcode,
                                        startAt: entryDateTime,
                                        quantity: "1"
                                    }
                                ],
                                payment: {
                                    email: findUser?.data?.userSavedCardDetail?.email
                                },
                            },
                            userSavedCardDetail: {
                                customerId: findUser?.data?.userSavedCardDetail?.customerId,
                                accountId: findUser?.data?.userSavedCardDetail?.accountId,
                                paymentCardId: findUser?.data?.userSavedCardDetail?.paymentCardId,
                                email: findUser?.data?.userSavedCardDetail?.email,
                                paymentCardType: findUser?.data?.userSavedCardDetail?.paymentCardType,
                                paymentCardNo: findUser?.data?.userSavedCardDetail?.paymentCardNo,
                                saveCardUserType: findUser?.data?.userSavedCardDetail?.saveCardUserType
                            },
                            mid: this.zoneDetails.mid,
                            source: "web"
                        };
                        if (this.codeType == "promoCode") {
                            Object.assign(postData, { promoCode: this.isAccessPromoCodeValid || null });
                        }
                        else if (this.codeType == 'accessCode') {
                            Object.assign(postData, { accessCode: this.isAccessPromoCodeValid || null });
                        }
                        // if (
                        //     Object.hasOwnProperty.call(this.zoneDetails, 'paymentServices') && Object.hasOwnProperty.call(this.zoneDetails.paymentServices, 'credit_card') && this.zoneDetails.paymentServices.credit_card == '1') {
                        //     Object.assign(postData.cardDetail, { paymentType: "CREDIT_CARD" })
                        // }
                        var odAddCard = await APIHelper(
                            "POST",
                            "/api/v1/prepaid/addCard",
                            postData
                        );
                        if (odAddCard?.data?.status == true) {
                            let url = window.location.origin + '/g/' + odAddCard?.data?.data?.bid;
                            window.location.replace(url);
                        } else if (odAddCard?.data?.status == false) {
                            this.alertDialog = true;
                            this.alertMsg = odAddCard?.data?.message;
                        }
                        this.odSessionLoading = false;
                    } catch (error) {
                        this.odSessionLoading = false;
                        console.log(error);
                    }
                }
                else {
                    this.odSessionLoading = false;
                    this.alertMsg = findUser?.data?.message ? findUser?.data?.message : "";
                    this.alertDialog = true;
                }
            }
            else {
                this.odSessionLoading = false;
                this.alertMsg = details?.data?.message ? details?.data?.message : "";
                this.alertDialog = true;
                if (details?.data?.message == 'Booking Already Exists') {
                    //url segment is app mixin computed property
                    let url = window.location.origin + '/g/' + details?.data?.data?.refno
                    window.location.replace(url);

                }
            }
        },
        uppercase() {
            if (rules.alphaNumeric(this.licencePlate) === "Must be alphanumeric") {
                this.licencePlate = this.licencePlate.replace(/[^\w\s]/gi, '')
            }
            this.licencePlate = this.licencePlate.trim()
            this.licencePlate = this.licencePlate.toUpperCase();
        },
        accessPromoCodeUpperCase() {
            if (rules.alphaNumeric(this.accessPromoCode) === "Must be alphanumeric") {
                this.accessPromoCode = this.accessPromoCode.replace(/[^\w\s]/gi, '')
            }
            this.accessPromoCode = this.accessPromoCode.trim()
            this.accessPromoCode = this.accessPromoCode.toUpperCase();
        },
        onContactInput(contactDetails) {
            this.isContactValid = contactDetails?.isValid;
            this.contactObj = Object.assign({}, contactDetails);
        },
        async addPayment() {
            this.addPaymentBtnLoading = true;

            let odDetails = localStorage.getItem('odDetails');
            odDetails = odDetails ? JSON.parse(odDetails) : {};
            localStorage.setItem("odDetails", JSON.stringify({ contact: this.contactObj, licencePlate: this.licencePlate, otpVerified: odDetails.otpVerified }));
            var exitDateTime = ""
            if (this.selectedOption == 'event') {
                var stopDateAndTime = this.selectedEvent.localEndDateTime !== null && this.selectedEvent.localEndDateTime !== undefined && this.selectedEvent.localEndDateTime !== "" ? this.selectedEvent.localEndDateTime.split(" ") : "";
                var stopTime = stopDateAndTime != null && stopDateAndTime != undefined && stopDateAndTime != null && stopDateAndTime.length != 0 ? format24TimeToAMPM(stopDateAndTime[1]) : ""
                var exitdate = this.selectedEvent.localEndDateTime !== null && this.selectedEvent.localEndDateTime !== undefined && this.selectedEvent.localEndDateTime !== "" && stopDateAndTime != null && stopDateAndTime != undefined && stopDateAndTime != null && stopDateAndTime.length != 0 ? stopDateAndTime[0] : "";
                exitDateTime = exitdate + ' ' + stopTime;
            }
            else {
                exitDateTime = this.onDemandTime != null || this.onDemandTime !== undefined || this.onDemandTime != "" ?
                    format(this.zoneDetails.timePickerConfiguration == '0' && this.customEndTimeMinDate ? addDays(this.onDemandTime, 1) : this.onDemandTime, 'yyyy-MM-dd')
                    +
                    " " +
                    (this.zoneDetails?.timePickerConfiguration == '0' ? format24TimeToAMPM(this.zoneDetails?.customizedEndTime) : format(this.onDemandTime, 'hh:mm a')) : ""
            }
            let entryDateTime = this.zoneDetails.timezone
                ? format(
                    dateToTimeZoneDate(new Date(),
                        this.zoneDetails.timezone
                    ),
                    "yyyy-MM-dd hh:mm a"
                )
                : format(new Date(), "yyyy-MM-dd HH:mm a");
            let commitData = {
                plate: this.licencePlate,
                plateRegion:{
                    country:this.plateRegion?.country,
                    state:this.plateRegion?.state,
                },
                contact: this.contactObj.formattedNumber,
                entryTime: entryDateTime,
                exitTime: exitDateTime,
                estimate: this.estimate,
                eventId: this.selectedEvent?.eventId || '',
            };
            if (this.codeType == "promoCode") {
                Object.assign(commitData, { promoCode: this.isAccessPromoCodeValid || null });
            }
            else if (this.codeType == 'accessCode') {
                Object.assign(commitData, { accessCode: this.isAccessPromoCodeValid || null });
            }
            let details = await APIHelper("POST", "/api/v1/prepaid/openTransaction", {
                vehicleNo: this.licencePlate,
                plateRegion:{
                    country:this.plateRegion?.country,
                    state:this.plateRegion?.state,
                },
                contact: this.contactObj.formattedNumber,
                entryTime: entryDateTime,
                exitTime: exitDateTime,
                zcode: this.zoneDetails?.zcode,

            });
            if (details?.data?.status) {
                let paramValues = {}
                if (this.codeType == "promoCode") {
                    Object.assign(commitData, { promoCode: this.isAccessPromoCodeValid || null });
                }
                else if (this.codeType == 'accessCode') {
                    Object.assign(commitData, { accessCode: this.isAccessPromoCodeValid || null });
                }
                this.addPaymentBtnLoading = false;
                this.$store.commit("SET_ONDEMAND_USERDETAILS", commitData);
                this.$router.replace({ path: "/ondemand/makepayment", params: paramValues });
            }
            else {
                this.addPaymentBtnLoading = false;

                if (details?.data?.message == 'Booking Already Exists') {
                    //url segment is app mixin computed property
                    let url = window.location.origin + '/g/' + details?.data?.data?.refno
                    window.location.replace(url);

                }
                this.alertMsg = details?.data?.message ? details?.data?.message : "";
                this.alertDialog = true;
            }
            this.addPaymentBtnLoading = false;


        },
    }

}
</script>

<style lang="less">
.information {
    background-color: #edf1f4;
}

.booking_header {
    font-size: 18px;
    z-index: 100;
    font-weight: bolder;
}

.add_licence_plate_btn {
    width: 100%;
    background-color: #f2555c !important;
    font-size: 18px !important;
    font-weight: 700 !important;
    letter-spacing: normal !important;
    height: 50px !important;
}

.country-selector.md.has-value .country-selector__input {
    border: none;
    border-bottom: 1px solid rgb(118, 118, 118);
}

.input-tel.md .input-tel__input {
    border: none;
    border-bottom: 1px solid rgb(118, 118, 118);
    margin-left: 2px;
}

.input-tel.is-focused.has-error .input-tel__input {
    box-shadow: none !important;
}

.input-tel.is-focused.is-valid .input-tel__input {
    box-shadow: none !important;
}


hr {
    border: 1px solid #ffbc1f;
}

.p-inputtext {
    background: transparent;
    border: none;
    border-bottom: 1px solid grey;
    text-align: left;
}

.no-border-select .v-select__selections {
    border-bottom: none !important;
    box-shadow: none !important;
}

.child-img {
    backdrop-filter: brightness(50%) !important;
}


// .vue__time-picker input.display-time{
//   border-bottom:1px solid #767676;
//     border-top:0px;
//     border-right:0px;
//     border-left:0px;
// }</style>
