<template>
  <v-container class="pa-" fluid v-if="!loading">
    <v-row class="text-center" no-gutters>
      <v-col cols="12">
        <v-img alt="PARKING.COM" class="my-3" height="42" contain src="@/assets/logo_huge_drk.png" />
      </v-col>
    </v-row>
    <v-row no-gutters class="mb-10">
      <v-col>
        <v-card class="mx-auto rounded-lg" max-width="374">
          <v-dialog v-model="loading" persistent>
            <v-card class="pa-4">
              <v-progress-circular indeterminate color="red"></v-progress-circular>
              <span class="ml-4" style="font-size: 14px">Please wait.</span>
            </v-card>
          </v-dialog>
          <template slot="progress">
            <v-progress-linear color="red" height="10" indeterminate></v-progress-linear>
          </template>
          <v-card color="transparent">
            <v-img class="rounded-t-lg " height="auto" :src="zoneDetails.hasOwnProperty('image') &&
              zoneDetails.image != '' &&
              zoneDetails.image != null
              ? zoneDetails.image
              : zoneImgURL
              " :lazy-src="zoneDetails.hasOwnProperty('image') &&
                zoneDetails.image != '' &&
                zoneDetails.image != null
                ? zoneDetails.image
                : zoneImgURL
                " alt="Parking.com garage">
              <v-row no-gutters>
                <v-col> <v-overlay absolute></v-overlay>
                </v-col>
                <v-col cols="12">
                  <p class="booking_header pt-3" style="color: white;position: relative;top: 0;margin-top: 45px;">
                    Welcome to
                    {{
                      zoneDetails.hasOwnProperty("zoneName")
                        ? zoneDetails.zoneName
                        : ""
                    }}
                  </p>
                </v-col>
                <v-col cols="12" v-if="isPreAuthEnabled">
                  <pre-auth-sheet style="position: absolute;bottom:0;z-index: 100;padding: 2px;"></pre-auth-sheet>

                </v-col>
              </v-row>


              <template v-slot:placeholder>
                <v-row class="fill-height ma-0" align="center" justify="center" no-gutters>
                  <v-progress-circular indeterminate color="grey lighten-5"></v-progress-circular>
                </v-row>
              </template>

            </v-img>
          </v-card>
          <v-card-text class="pa-0">
            <v-form ref="detailsForm">
              <v-container fluid pa-0>
                <v-row no-gutters class="pt-2 information pa-0">
                  <v-col cols="2" class="align-self-center">
                    <v-img src="@/assets/list_divider_step.imageset/list_divider_step.png" width="45px"
                      v-if="!infoStepperFlag">
                      <div class="row text-center align-self-center pl-5">
                        <div class="col-12 text-center">
                          <span style="font-size:large;font-weight:bold;color:white">1</span>
                        </div>
                      </div>
                    </v-img>
                    <v-img src="@/assets/list_divider_step_completed.imageset/list_divider_step_completed.png"
                      width="45px" v-if="infoStepperFlag">
                      <div class="row text-center pl-5">
                        <div class="col-12 text-center">
                          <span style="font-size:larger;font-weight:bold;color:white">✓</span>
                        </div>
                      </div>
                    </v-img>
                  </v-col>
                  <v-col cols="10" class="mt-3">
                    <p style="color:#F2555C;font-weight:bolder">Your Information</p>
                  </v-col>
                </v-row>
                <v-row no-gutters>
                  <v-col cols="12" class="px-4  text-center color-black" style="font-size: 12px">
                    <v-row no-gutters class="py-2">
                      <v-col cols="1" class="text-right align-self-center">
                        <v-icon class="material-icons-outlined">send_to_mobile</v-icon>
                      </v-col>
                      <v-col cols="11" class="px-2">
                        <VuePhoneNumberInput v-model="contactObj.phoneNumber"
                          :disabled="zoneDetails.odAuthenticationFlow == 1" size="md" @update="onContactInput"
                          show-code-on-list :default-country-code="defaultCountryCode" :color="contactOptions.color"
                          :valid-color="contactOptions.validColor" :error-color="contactOptions.errorColor"
                          :border-radius="contactOptions.contactBorderRadius" :error="!isContactValid" required
                          :translations="contactOptions.translations" />
                      </v-col>
                    </v-row>
                    <v-row no-gutters class="py-2">
                      <v-col cols="1" class="text-right align-self-center">
                        <v-icon class="material-icons-outlined">pin</v-icon>
                      </v-col>
                      <v-col cols="11" class="px-2">
                        <v-text-field class="pt-0 plate-region-label" v-model="licencePlate" hide-details="auto"
                          maxlength="8" :rules="[rules.alphaNumeric]" @keyup="uppercase" label="License Plate *"
                          clearable></v-text-field>
                      </v-col>
                    </v-row>

                    <v-row no-gutters class="pt-1">
                      <v-spacer></v-spacer>
                      <v-col cols="11" class="px-2 py-0 pb-3">
                        <p class="text-left mb-0" style="font-size: 10px; line-height: 1.5">
                          Enter your full plate number to avoid citation. Max of
                          8 characters. Do not include spaces or special
                          characters.
                        </p>
                      </v-col>
                    </v-row>
                    <v-row no-gutters class="pa-2">
                      <v-col cols="1"></v-col>
                      <v-col cols="5" class="pr-1">
                        <v-select :disabled="!checkValidValue(licencePlate)" class="pt-0 plate-region-label" dense
                          v-model="plateRegion.country" :items="countries" item-text="name" item-value="abbreviation"
                          :menu-props="{ offsetY: true }" hide-details label="Country *" @change="onCountryChange()">
                          <template v-slot:selection="data">
                              <v-row no-gutters>
                                <v-col cols="2" v-if="Object.hasOwnProperty.call(data, 'item') && Object.hasOwnProperty.call(data.item, 'abbreviation')">
                                  <vue-country-flag
                                :country="data.item.abbreviation"
                                class="flag"
                                size='small'
                              />
                                </v-col>
                                <v-col cols="10" class="country-name" v-if="Object.hasOwnProperty.call(data, 'item') && Object.hasOwnProperty.call(data.item, 'name')">
                                  <span class="px-2">{{
                                data.item.name
                              }}</span>
                                </v-col>
                              </v-row>
                            </template>

                            <template v-slot:item="data">
                              <template
                                v-if="Object.hasOwnProperty.call(data, 'item') && Object.hasOwnProperty.call(data.item, 'abbreviation') && Object.hasOwnProperty.call(data.item, 'name')"
                              >
                                <div class="d-flex align-center">
                                  <vue-country-flag
                                    :country="data.item.abbreviation"
                                    class="flag"
                                  />
                                  <span class="country-name px-2 pt-2">{{
                                    data.item.name
                                  }}</span>
                                </div>
                              </template>
                            </template>
                        </v-select>
                      </v-col>
                      <v-col cols="6" class="pl-1">
                        <v-select :disabled="!checkValidValue(licencePlate)" class="pt-0 plate-region-label" dense
                          v-model="plateRegion.state" :items="countryStates" item-text="name" item-value="abbreviation"
                          hide-details="auto" :rules="[rules.isRequired]" :label="plateRegion.country!='' ? plateRegion.country=='US' ? 'State *' : 'Province *' : 'State *'"></v-select>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
                <v-row no-gutters class="pt-2 information pa-0"
                  v-if="mySpotDetails != null && mySpotDetails != '' && mySpotDetails != undefined && mySpotDetails.length > 0">
                  <v-col cols="2" class="align-self-center">
                    <v-img src="@/assets/list_divider_step.imageset/list_divider_step.png" width="45px"
                      v-if="selectedOption != 'event'">
                      <div class="row text-center align-self-center pl-5">
                        <div class="col-12 text-center">
                          <span style="font-size:large;font-weight:bold;color:white"> {{ 2 }}</span>
                        </div>
                      </div>
                    </v-img>
                    <v-img src="@/assets/list_divider_step_completed.imageset/list_divider_step_completed.png"
                      width="45px" v-if="selectedOption == 'event'">
                      <div class="row text-center pl-5">
                        <div class="col-12 text-center">
                          <span style="font-size:larger;font-weight:bold;color:white">✓</span>
                        </div>
                      </div>
                    </v-img>
                  </v-col>
                  <v-col cols="10" class="mt-3">
                    <p style="color:#F2555C;font-weight:bolder">Select An Event</p>
                  </v-col>
                </v-row>
                <v-row no-gutters class="pa-4"
                  v-if="mySpotDetails != null && mySpotDetails != '' && mySpotDetails != undefined && mySpotDetails.length > 0">
                  <v-col cols="12">
                    <v-select hide-details="auto" append-icon v-model="selectedEvent" solo
                      label="Select An Event from the list of events"
                      :outlined="selectedEvent != null && selectedEvent != '' && selectedEvent != undefined ? false : true"
                      flat :items="mySpotDetails" @change="eventRateDateChange(selectedEvent)" class="no-border-select"
                      item-text="eventName" item-value="item">

                      <template slot="selection" slot-scope="data">
                        <!-- HTML that describe how select should render selected items -->
                        <v-row>
                          <v-col cols="8">
                            <p style="font-weight: 500;margin-bottom: -2px;font-size: 13px;" class="pa-0"> {{
                              data.item.eventName }} </p>
                            <p class="pa-0" style="font-size: 10px;">Exit by : <span style="font-weight: 700;">{{
                              eventModeLocalTime(data.item.localEndDateTime) }}</span></p>
                          </v-col>
                          <v-col cols="4" class="mt-4 text-right">
                            <p style="color: #F2555C;font-weight: 500;font-size: 13px;">$ {{ data.item.price !=
                              undefined
                              ? Number(data.item.price).toFixed(2) : '0.00'}}</p>
                          </v-col>
                        </v-row>
                      </template>

                      <template slot="item" slot-scope="data">
                        <!-- HTML that describe how select should render items when the select is open -->
                        <v-row>
                          <v-col cols="8">
                            <p style="font-weight: 500;margin-bottom: -2px;font-size: 13px;" class="pa-0"> {{
                              data.item.eventName }} </p>
                            <p class="pa-0" style="font-size: 10px;">Exit by : <span style="font-weight: 700;">{{
                              eventModeLocalTime(data.item.localEndDateTime) }}</span></p>
                          </v-col>
                          <v-col cols="4" class="mt-4 text-right">
                            <p style="color: #F2555C;font-weight: 500;font-size: 13px;">$ {{ data.item.price !=
                              undefined
                              ? Number(data.item.price).toFixed(2) : '0.00' }}</p>
                          </v-col>
                        </v-row>
                      </template>
                    </v-select>

                  </v-col>
                </v-row>
                <v-row no-gutters class="pt-2 information pa-0">
                  <v-col cols="2" class="align-self-center">
                    <v-img src="@/assets/list_divider_step.imageset/list_divider_step.png" width="45px"
                      v-if="!timeStepperFlag || (selectedOption != 'date' && selectedOption != null)">
                      <div class="row text-center align-self-center pl-5">
                        <div class="col-12 text-center">
                          <span style="font-size:large;font-weight:bold;color:white">{{ 2
                            }}</span>
                        </div>
                      </div>
                    </v-img>
                    <v-img src="@/assets/list_divider_step_completed.imageset/list_divider_step_completed.png"
                      width="45px" v-if="timeStepperFlag && selectedOption == 'date'">
                      <div class="row text-center pl-5">
                        <div class="col-12 text-center">
                          <span style="font-size:larger;font-weight:bold;color:white">✓</span>
                        </div>
                      </div>
                    </v-img>
                  </v-col>
                  <v-col cols="10" class="mt-3">
                    <p style="color:#F2555C;font-weight:bolder">{{ mySpotDetails != null &&
                      mySpotDetails != '' && mySpotDetails != undefined && mySpotDetails.length > 0 ? 'Or ' : '' }} Set
                      Your Exit Date and Time</p>
                  </v-col>
                </v-row>
                <v-row no-gutters class="pt-2 px-3 ">
                  <v-spacer></v-spacer>
                  <v-col cols="11" class="px-2 py-0">
                    <p class="text-left ma-0" v-if="templateFlags == 'atlantic-flag'">If you're a hotel guest, this
                      is
                      your checkout date. </p>
                  </v-col>
                </v-row>
                <v-row no-gutters>
                  <v-col cols="12" class="px-4  text-center color-black" style="font-size: 12px">
                    <v-row no-gutters class="pb-4">
                      <v-col cols="1" class="text-right align-self-center">
                        <v-icon class="material-icons-outlined">schedule</v-icon>
                      </v-col>
                      <v-col cols="11" class="px-3" v-if="zoneDetails.timePickerConfiguration == '0'">
                        <v-text-field @click="openDatePicker()" readonly hide-details="auto"
                          placeholder="Select a duration to exit" v-model="formattedTime"></v-text-field>
                        <p class="black--text text-left pb-4"
                          v-if="Object.hasOwnProperty.call(zoneDetails, 'timePickerConfiguration') && zoneDetails.timePickerConfiguration == '0' && this.selectedOption == 'date'"
                          style="padding-bottom: 0 !important;margin-bottom: 0 !important  ;">{{ zoneEndTimeLabel }}</p>

                      </v-col>
                      <v-col cols="11" class="px-3" v-if="zoneDetails.timePickerConfiguration == '1'">
                        <v-text-field @click="openSphereTimePicker()" readonly placeholder="Select a duration to exit"
                          hide-details="auto" v-model="formattedTime"></v-text-field>
                      </v-col>
                    </v-row>
                    <v-row class="text-left mt-0 px-2 py-0 pb-3" v-if="getTodaysHoursOfOperation">
                      <v-col cols="1"></v-col>
                      <v-col class="pt-0">
                        <p style="
                                                   color: #1976d2;
                                            font-weight: bold;
                                            line-height: 16px;
                                                " class="ma-0">
                          HOURS OF OPERATION
                        </p>
                        <p class="ma-0">
                          Today :
                          {{
                            getTodaysHoursOfOperation
                              ? "Open 24 / 7"
                              : ""
                          }}
                        </p>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
                <v-row no-gutters class="pt-2 information pa-0"
                  v-if="estimate != null && Object.keys(estimate).length != 0">
                  <v-col cols="2" class="align-self-center">
                    <v-img src="@/assets/list_divider_step.imageset/list_divider_step.png" width="45px"
                      v-if="!paymentStepperFlag">
                      <div class="row text-center align-self-center pl-5">
                        <div class="col-12 text-center">
                          <span style="font-size:large;font-weight:bold;color:white">3</span>
                        </div>
                      </div>
                    </v-img>
                    <v-img src="@/assets/list_divider_step_completed.imageset/list_divider_step_completed.png"
                      width="45px" v-if="paymentStepperFlag">
                      <div class="row text-center pl-5">
                        <div class="col-12 text-center">
                          <span style="font-size:larger;font-weight:bold;color:white">✓</span>
                        </div>
                      </div>
                    </v-img>
                  </v-col>
                  <v-col cols="10" class="mt-3">
                    <p style="color:#F2555C;font-weight:bolder">Your Parking Fees</p>
                  </v-col>
                </v-row>
                <v-row no-gutters v-if="estimate != null && Object.keys(estimate).length != 0">
                  <v-col cols="12" class="px-10 text-center " style="font-size: 12px">
                    <v-row no-gutters class="pt-2 px-3">
                      <v-col cols="4" class="text-left">
                        <p style="font-size: small" class="black--text">
                          Parking
                        </p>
                      </v-col>
                      <v-spacer></v-spacer>
                      <v-col cols="4" class="text-left">
                        <p style="font-size: small" class="black--text text-right">
                          {{ estimate.hasOwnProperty("quotedRegularPrice") ? "$ " +
                            estimate.quotedRegularPrice.toFixed(2)
                            : "-" }}

                        </p>
                      </v-col>
                    </v-row>
                    <v-row no-gutters class="mt-0 px-3">
                      <v-col cols="4" class="text-left">
                        <v-row no-gutters>
                          <v-col cols="11">
                            <p style="font-size: small" class="black--text">
                              Service Fee
                            </p>
                          </v-col>
                          <v-col cols="1">
                            <v-tooltip bottom v-model="show">

                              <template v-slot:activator="{ on, attrs }">
                                <v-icon class="material-icons-outlined" dark x-small color="primary" v-bind="attrs"
                                  @click="show = !show" v-on="on">info</v-icon>
                              </template>
                              <span>This fee helps cover the use of the technology that provides a better parking
                                experience.</span>
                            </v-tooltip>
                          </v-col>

                        </v-row>
                      </v-col>
                      <v-spacer></v-spacer>
                      <v-col cols="4" class="text-left">
                        <p style="font-size: small" class="black--text text-right">
                          {{ estimate.hasOwnProperty("totalFee") ? "$ " + estimate.totalFee.toFixed(2) : "-" }}
                        </p>
                      </v-col>
                    </v-row>
                    <hr />
                    <v-row no-gutters class="mt-2 px-3">
                      <v-col cols="6" class="text-left">
                        <p style="font-weight: bold;color:#F2555C;font-size:15px">
                          Purchase Total <sup>*</sup>
                        </p>
                      </v-col>
                      <v-spacer></v-spacer>
                      <v-col cols="4" class="text-left">
                        <p style="font-size: small;font-weight: bold;color:#F2555C" class=" text-right">
                          {{ estimate.hasOwnProperty("grandTotal") ? "$ " + estimate.grandTotal.toFixed(2) : "-" }}

                        </p>
                      </v-col>
                      <p>
                        *Includes state and local taxes of {{ estimate.hasOwnProperty("totalTax") ? "$ " +
                          estimate.totalTax.toFixed(2) : "-" }}
                      </p>
                    </v-row>
                  </v-col>
                  <v-col class="text-center">
                    <p> <span style="font-weight: bolder;"> Validating Your Parking ?</span> <br>
                      Enter payment first. You will be able to validate on the next screen.
                      <v-tooltip top class="align-self-center" v-model="showValToolTip">

                        <template v-slot:activator="{ on, attrs }">
                          <v-icon color="primary" v-bind="attrs" v-on="on" small
                            @click="showValToolTip = !showValToolTip" class="material-icons-outlined">info</v-icon>
                        </template>
                        Once the validation is applied, parking fees will be adjusted accordingly. Valid only at
                        participating locations.
                      </v-tooltip>
                    </p>
                  </v-col>
                </v-row>
              </v-container>
            </v-form>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <out-standing-payments-view :outStandingPayments="outStandingPayments" :outStandingBookingId="outStandingBookingId" :outStandingLPRImage="outStandingLPRImage"
      typeOfBooking="OutStandingODV" :iosDevice="iosDevice"></out-standing-payments-view>
    <SphereDateTimePicker :sheet="sheet"
      :maxParkingTime="OnDemandZoneDetails.maxParkingTime || zoneDetails.maxParkingTime"
      :timezone="zoneDetails.timezone"></SphereDateTimePicker>
    <v-dialog v-model="dateDialog" max-width="350px">
      <v-date-picker color="#f2555c" v-model="selectedEndDate" :min="minDate" :max="maxDate" @change="setDateAndTime()">

      </v-date-picker>
    </v-dialog>
    <v-dialog v-model="alertDialog" persistent max-width="290">
      <v-card class="pa-0">
        <v-card-title class="justify-center" style="position: relative">
          <v-icon x-large color="red accent-2">mdi-alert-outline</v-icon>
        </v-card-title>
        <v-card-text class="text-center mt-5">
          <span style="font-size: 20px">{{ alertMsg }}</span>
        </v-card-text>
        <v-card-actions class="text-center pb-6">
          <v-container pa-0 fluid>
            <v-row no-gutters>
              <v-col cols="12">
                <v-btn rounded block elevation="0" class="white--text exit_dialog_btn"
                  @click="alertDialog = false">Close</v-btn>
              </v-col>
            </v-row>
          </v-container>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-footer fixed class="pl-0 pr-0 pb-0 " app>
      <v-row no-gutters>
        <v-col cols="12" class="pt-0" style="display: flex;flex-direction: row;background: #0287D214;
                                            " v-if="userCardNumber != '' && checkOTPVerified">
          <v-row no-gutters>
            <v-col cols="6" class="text-left mt-2 pl-2">
              <p>* {{ userCardNumber }} credit card on file</p>
            </v-col>
            <v-col cols="6" class="text-right  pr-2">
              <v-btn @click="addPayment()" :disabled="disableSubmitBtn" color="#1a77d2" text
                style="text-transform: none;;">Change</v-btn>
              <v-icon class="material-icons-outlined align-self-center">arrow_right</v-icon>
            </v-col>
          </v-row>
        </v-col>
        <!-- If there is existing CC and if user wants to change -->
        <v-col cols="12" class="pl-2 pr-2">
          <v-container fluid pa-0>
            <v-row no-gutters class="pt-4">
              <v-col :cols="paymentCols ? '12' : '6'" class="px-4" v-if="userCardNumber != '' && checkOTPVerified">
                <v-btn @click="createOdTransaction()" large rounded elevation="20" color="#F2555C" width="100%"
                  style="text-transform:none;border-radius: 60px !important" class=" white--text "
                  :disabled="disableSubmitBtn" :loading="odSessionLoading">
                  <v-row no-gutters>
                    <v-col cols="12" class="text-center">
                      <p style="font-weight: bold;margin-bottom: 0;">Pay with CC</p>
                    </v-col>
                    <v-col cols="12" class="text-center">
                      <p style="font-weight: 14px;margin-bottom: 0;"> (* {{ userCardNumber }})
                      </p>
                    </v-col>
                  </v-row>

                </v-btn>
              </v-col>
              <!-- If user wants to add new Card -->
              <v-col :cols="paymentCols ? '12' : '6'" class="px-4 pb-3"
                v-if="userCardNumber == '' || !checkOTPVerified">
                <v-btn rounded elevation="20" large class=" white--text " color="#f2555c" width="100%"
                  :disabled="disableSubmitBtn" :loading="addPaymentBtnLoading" @click="addPayment"
                  style="text-transform:none;border-radius: 60px !;">Add Credit Card</v-btn>
              </v-col>
              <v-col cols="6" class="px-4 pb-3"
                v-if="iosDevice && Object.hasOwnProperty.call(zoneDetails, 'paymentServices') && Object.hasOwnProperty.call(zoneDetails.paymentServices, 'apple_pay') && zoneDetails.paymentServices.apple_pay == '1'">
                <!-- :ios-device="iosDevice && Object.hasOwnProperty.call(bookingDetails, 'booking') && Object.hasOwnProperty.call(bookingDetails.booking, 'paymentServices') && Object.hasOwnProperty.call(bookingDetails.booking.paymentServices, 'apple_pay') && bookingDetails.booking.paymentServices.apple_pay == '1'" -->
                <apple-pay width='100%' height="43px" style="border-radius: 50px;" :pendingState="false"
                  :amountRequired="estimate != null && estimate != undefined && estimate != '' && Object.hasOwnProperty.call(estimate, 'grandTotal') ? Number(estimate.grandTotal).toFixed(2) + '' : '1.00'"
                  :disabled="disableSubmitBtn"
                  :iosDevice="iosDevice && Object.hasOwnProperty.call(zoneDetails, 'paymentServices') && Object.hasOwnProperty.call(zoneDetails.paymentServices, 'apple_pay') && zoneDetails.paymentServices.apple_pay == '1'"
                  :licensePlate="licencePlate" :contactObj="contactObj" :typeOfBooking="'OnDemandValidation'"></apple-pay>
              </v-col>
              <!-- v-if="!iosDevice && Object.hasOwnProperty.call(bookingDetails, 'booking') && Object.hasOwnProperty.call(bookingDetails.booking, 'paymentServices') && Object.hasOwnProperty.call(bookingDetails.booking.paymentServices, 'google_pay') && bookingDetails.booking.paymentServices.google_pay == '1'" -->
              <v-col cols="6" class="px-2 pb-3"
                v-if="!iosDevice && Object.hasOwnProperty.call(zoneDetails, 'paymentServices') && Object.hasOwnProperty.call(zoneDetails.paymentServices, 'google_pay') && zoneDetails.paymentServices.google_pay == '1'">
                <google-pay width='100%' height="43px" :disabled="disableSubmitBtn" :pendingState="false"
                  :amountRequired="estimate != null && estimate != undefined && estimate != '' && Object.hasOwnProperty.call(estimate, 'grandTotal') ? Number(estimate.grandTotal).toFixed(2) + '' : '0.00'"
                  :licensePlate="licencePlate" :typeOfBooking="'OnDemandValidation'" :contactObj="contactObj"></google-pay>
              </v-col>
            </v-row>
          </v-container>


        </v-col>
      </v-row>
    </v-footer>
  </v-container>
</template>

<script>
// import Vue from "vue";
import { mapGetters } from "vuex";
import PreAuthSheet from "@/uiComponents/PreAuthSheet.vue";
import SphereDateTimePicker from '@/uiComponents/SphereDateTimePicker.vue'
import GooglePay from '@/views/GooglePay.vue'
import ApplePay from "@/views/ApplePay.vue";
import { EventBus } from "@/lib/EventBus";
import { addDays, addHours, addMinutes } from "date-fns";
import OutStandingPaymentsView from "@/components/OutStandingPaymentsView.vue";
import { format, differenceInMinutes } from "date-fns";
import { dateToTimeZoneDate, format24TimeToAMPM } from "@/utils/formatDateTime";
import axios from 'axios';
// import axios from "axios";
import APIHelper from "../../apiHelper";
// import VueTimePicker from '@/uiComponents/VueTimePicker';
import VuePhoneNumberInput from "vue-phone-number-input";
import "vue-phone-number-input/dist/vue-phone-number-input.css";
// import moment from "moment";
import { getURLParamValue } from "@/utils/util";
// Vue.use(moment);
import rules from "@/utils/rules";
import VueCountryFlag from "vue-country-flag";
export default {
  data: (vm) => ({
    squareEntryTime: "",
    outStandingBookingId: "",
    outStandingLPRImage: [],
    squareExitTime: "",
    selectedEndDate: "",
    dateDialog: false,
    sheet: false,
    zoneEndTimeLabel: "",
    stopAt: vm.odvTime,
    showValToolTip: false,
    selectedOption: null,
    selectedEvent: null,
    userCardNumber: "",
    show: false,
    odSessionLoading: false,
    iosDevice: false,
    alertDialog: false,
    timeValue: null,
    alertMsg: "",
    stopTime: "",
    openTimePicker: false,
    date: "",
    odvTime: "",
    exitTimeSelected: {},
    timePickerHrRange: null,
    timeExceeded: false,
    timePickerMinRange: null,
    minDate: null,
    maxDate: null,
    disableAddPaymentBtn: false,
    maxDateTime: null,
    getRateCall: false,
    getRateText: "",
    outStandingPayments: false,
    Timemenu: false,
    startDate: "",
    estimate: null,
    Datemenu: false,
    exitDate: null,
    dateFormatted: null,
    rules: rules,
    isContactValid: true,
    licencePlate: "",
    contactObj: {},
    loading: false,
    contactOptions: {
      color: "#0288D1",
      validColor: "#0288D1",
      contactBorderRadius: 0,
      errorColor: "#FF5252",
      defaultCountry: "US",
      translations: {
        phoneNumberLabel: "Contact No",
      },
    },
    addPaymentBtnLoading: false,
  }),
  components: {
    VuePhoneNumberInput,
    GooglePay,
    ApplePay,
    SphereDateTimePicker,
    OutStandingPaymentsView,
    PreAuthSheet,
    VueCountryFlag
    // VueTimePicker,
  },
  beforeRouteEnter(to, from, next) {
    next(async (vm) => {
      vm.getMobileOperatingSystem() === 'iOS' ? vm.iosDevice = true : vm.iosDevice = false;
      if (vm.zoneDetails?.showOutstandingPayment == 1) {
        await vm.checkOutStandingPayments()
      }
      if (from.path === "/ondemand/makepayment") {
        switch (vm.templateFlags) {
          case 'ondemand-flag':
            {
              vm.contactObj =
                vm.odDetails != null &&
                  Object.hasOwnProperty.call(vm.odDetails, "userDetail") &&
                  Object.hasOwnProperty.call(vm.odDetails?.userDetail, "contact") &&
                  vm.odDetails?.userDetail?.contact != undefined &&
                  vm.odDetails?.userDetail?.contact != null
                  ? Object.assign(vm.contactObj, {
                    countryCode:
                      vm.odDetails?.userDetail?.contact.slice(0, -10) == "+91"
                        ? "IN"
                        : vm.odDetails?.userDetail?.contact.slice(0, -10) == "+1"
                          ? "US"
                          : "",
                    phoneNumber: vm.odDetails?.userDetail?.contact,
                    formattedNumber: "+" + vm.odDetails.userDetail.contact,
                  })
                  : {};
              vm.licencePlate = vm.odDetails?.userDetail.plate
                ? vm.odDetails?.userDetail.plate
                : "";
              break;
            }

        }

      }
      if (localStorage.getItem('odDetails')) {
        let odDetails = localStorage.getItem('odDetails');
        odDetails = odDetails ? JSON.parse(odDetails) : {};
        vm.contactObj = odDetails?.contact ? Object.assign({}, odDetails.contact) : vm.contactObj;
        vm.licencePlate = odDetails?.licencePlate ? odDetails.licencePlate : vm.licencePlate;
      }
      let searchParams = window.location.search.split("=");
      let searchParamKey = searchParams.length > 0 ? searchParams[0] : "";
      let searchParamValue = searchParams.length > 0 ? searchParams[1] : "";
      if (window.location.href.includes("ondemand")) {
        vm.$store.commit('SET_TEMPLATE_FLAGS', 'ondemand-flag')
      }
      switch (searchParamKey) {
        case "?zcode":
          await vm.getZoneDetails(searchParamKey, searchParamValue);
          break;
        case "?gateid":
          //await self.getZoneDetails(searchParamKey, searchParamValue);
          break;
      }
      if (vm.zoneDetails?.odAuthenticationFlow == 1 && !vm.checkOTPVerified) {
        window.location.replace(window.location.origin + '?zcode=' + vm.zoneDetails?.zcode + '#/ondemand/create')
      }
    });


  },
  watch: {
    infoStepperFlag() {
      if (this.infoStepperFlag) {
        this.findUser()
      }
    },
    // exitDate(newVal, oldVal) {
    //   let date = format(
    //     addMinutes(new Date(this.exitDate), 15),
    //     "yyyy-MM-dd"
    //   )
    //   this.dateFormatted = this.formatDate(date);
    //   if (oldVal != newVal && this.exitDate == format(
    //     dateToTimeZoneDate(addMinutes(new Date(), 15),
    //       this.onDemandZoneDetails.timeZone
    //     ),
    //     "yyyy-MM-dd"
    //   )) {
    //     this.getRateCall = true;
    //     this.openDateTimePicker();
    //   }
    //   else {
    //     this.getRateCall = false
    //   }
    // },

    // // time(newVal, oldVal) {
    // //   if (oldVal != null && newVal != null && oldVal != newVal && oldVal != "" && newVal != "" && (isValid(newVal))) {
    // //     this.round(newVal);
    // //   }
    //   // this.setTimePicker(newVal);
    // },
  },
  destroyed() {
    EventBus.$off('SQUARE_PAY_ODV');
  },
  async mounted() {
    if (localStorage.getItem('odDetails')) {
      let odDetails = localStorage.getItem('odDetails');
      odDetails = odDetails ? JSON.parse(odDetails) : {};
      this.contactObj = odDetails?.contact ? Object.assign({}, odDetails.contact) : this.contactObj;
      this.licencePlate = odDetails?.licencePlate ? odDetails.licencePlate : this.licencePlate;
    }
    if (this.zoneDetails?.showOutstandingPayment == 1) {
      await this.checkOutStandingPayments()
    }
    this.getMobileOperatingSystem() === 'iOS' ? this.iosDevice = true : this.iosDevice = false;
    EventBus.$on('SQUARE_PAY_ODV', async (tokenResult, verfiedToken, paymentType) => {
      await this.squarePay(tokenResult, verfiedToken, paymentType)
    })
    this.setDefaultDate()
    EventBus.$on('EXIT_TIME_SELECTED', (duration) => {
      this.applyDuration(duration);
      this.dateTimeChange();
      this.sheet = false
    })

    // this.setTimePicker(); //moment(new Date()).format("YYYY-MM-DD hh:mm a").toString();
  },
  methods: {
    /**
        * @method applyDuration - applies the duration selected from duration picker to the current time.
        * @param {*} duration - format {day:selectedDay, hour:selectedHour, minute:selectedMinute}
        */
    applyDuration(duration) {
      let endTime = this.zoneDetails.timezone ? dateToTimeZoneDate(new Date(), this.zoneDetails?.timezone) : new Date();
      let addedDays = addDays(endTime, duration.day);
      let addedhours = addHours(addedDays, duration.hour);
      let addedMinutes = addMinutes(addedhours, Number(duration.minute));
      this.odvTime = addedMinutes;
    },
    async checkOutStandingPayments() {
      try {

        let zcode = getURLParamValue("zcode");

        let details = await APIHelper("POST", "/api/v1/prepaid/openTransaction", {
          contact: this.contactObj?.formattedNumber,
          zcode: zcode
        });

        if (details?.data?.data?.outStandingPaymentDetails?.status) {
          this.requestBtnLoading = false;

          this.outStandingBookingId = details?.data?.data?.outStandingPaymentDetails?.bid;
          this.outStandingLPRImage = details?.data?.data?.outStandingPaymentDetails?.lprImage || [];
          await this.getBookingDetails(details?.data?.data?.outStandingPaymentDetails?.bid);
          this.outStandingPayments = details?.data?.data?.outStandingPaymentDetails?.status && details?.data?.data?.outStandingPaymentDetails?.status == 'BLOCKED' && this.zoneDetails.showOutstandingPayment == 1
            ? true
            : false;
        }


      }
      catch (error) {
        this.requestBtnLoading = false;

        console.log(error)
      }
    },
    async getBookingDetails(bookingId) {
      this.loading = true;
      try {
        var bookingDetails = await APIHelper(
          "GET",
          "/api/v1/booking/config/" + bookingId
        );
        this.$store.commit(
          "SET_BOOKING_DETAILS",
          bookingDetails.data?.data ? bookingDetails.data.data : null
        );
        if (this.bookingDetails?.outStandingPaymentDetails?.status) {
          this.outStandingPayments = this.bookingDetails
            ?.outStandingPaymentDetails?.status && this.bookingDetails?.outStandingPaymentDetails?.status == 'BLOCKED' && this.bookingDetails?.zone?.showOutstandingPayment == 1
            ? true
            : false;
        }
        this.loading = false
      } catch (error) {
        this.loading = false
        console.log(error);
      }
    },
    async squarePay(tokenResult, verfiedToken, paymentType) {
      this.$store.commit("SET_LOADING_STATUS", {
        property: "APP",
        pending: true,
      });
      try {
        var exitDateTime = ""
        if (this.selectedOption == 'event') {
          var stopDateAndTime = this.selectedEvent.localEndDateTime !== null && this.selectedEvent.localEndDateTime !== undefined && this.selectedEvent.localEndDateTime !== "" ? this.selectedEvent.localEndDateTime.split(" ") : "";
          var stopTime = stopDateAndTime != null && stopDateAndTime != undefined && stopDateAndTime != null && stopDateAndTime.length != 0 ? format24TimeToAMPM(stopDateAndTime[1]) : ""
          var exitdate = this.selectedEvent.localEndDateTime !== null && this.selectedEvent.localEndDateTime !== undefined && this.selectedEvent.localEndDateTime !== "" && stopDateAndTime != null && stopDateAndTime != undefined && stopDateAndTime != null && stopDateAndTime.length != 0 ? stopDateAndTime[0] : "";
          exitDateTime = exitdate + ' ' + stopTime;
        }
        else {
          exitDateTime = this.odvTime != null || this.odvTime !== undefined || this.odvTime != "" ?
            format(this.zoneDetails.timePickerConfiguration == '0' && this.customEndTimeMinDate ? addDays(this.odvTime, 1) : this.odvTime, 'yyyy-MM-dd')
            +
            " " +
            (this.zoneDetails?.timePickerConfiguration == '0' ? format24TimeToAMPM(this.zoneDetails?.customizedEndTime) : format(this.odvTime, 'hh:mm a')) : ""
        }
        let entryDateTime = this.zoneDetails.timezone
          ? format(
            dateToTimeZoneDate(new Date(),
              this.zoneDetails.timezone
            ),
            "yyyy-MM-dd hh:mm a"
          )
          : format(new Date(), "yyyy-MM-dd HH:mm a");
        let details = await APIHelper("POST", "/api/v1/prepaid/openTransaction", {
          vehicleNo: this.licencePlate,
          plateRegion: {
            country: this.plateRegion?.country,
            state: this.plateRegion?.state,
          },
          contact: this.contactObj?.formattedNumber,
          entryTime: entryDateTime,
          exitTime: exitDateTime,
          zcode: this.zoneDetails?.zcode,

        });
        if (details?.data?.status) {
          this.odSessionLoading = true;
          let postData = {
            userDetail: {
              eventId: this.selectedEvent?.eventId || "",
              plate: this.licencePlate,
              plateRegion: {
                country: this.plateRegion?.country,
                state: this.plateRegion?.state,
              },
              contact: this.contactObj?.formattedNumber,
              exitTime: exitDateTime,
              eventType: "OD",
            },
            cardDetail: {
              userDevice: "2",
              orderType: "3",
              entry: [
                {
                  locationCode: this.zoneDetails?.zcode,
                  startAt: entryDateTime,
                  quantity: "1",
                },
              ],
              paymentType: paymentType,
              billingDetails: tokenResult?.details?.billing,
              verification_token: verfiedToken,
              source_id: tokenResult.token,
              phoneNumber: this.contactObj?.formattedNumber,
              email: tokenResult?.details?.shipping?.contact?.email || "",
            },
            mid: this.zoneDetails.mid,
            source: "web",
          };
          var odAddCard = await APIHelper(
            "POST",
            "/api/v1/prepaid/addCard",
            postData
          );
          if (odAddCard?.data?.status == true) {
            this.$store.commit("SET_LOADING_STATUS", {
              property: "APP",
              pending: false,
            });
            //url segment is app mixin computed property
            let url =
              window.location.origin + '/g/' + odAddCard?.data?.data?.bid;
            window.location.replace(url);
          } else if (odAddCard?.data?.status == false) {
            this.$store.commit("SET_LOADING_STATUS", {
              property: "APP",
              pending: false,
            });
            this.alertDialog = true;
            this.alertMsg = odAddCard?.data?.message;
          }
          this.odSessionLoading = false;
        }
        else {
          this.$store.commit("SET_LOADING_STATUS", {
            property: "APP",
            pending: false,
          });
          this.odSessionLoading = false;
          this.alertMsg = details?.data?.message ? details?.data?.message : "";
          this.alertDialog = true;
          if (details?.data?.message == 'Booking Already Exists') {
            //url segment is app mixin computed property
            let url = window.location.origin + '/g/' + details?.data?.data?.refno
            window.location.replace(url);

          }
        }
      } catch (error) {
        this.$store.commit("SET_LOADING_STATUS", {
          property: "APP",
          pending: false,
        });
        this.odSessionLoading = false;
        console.log(error);
      }
    },
    setDateAndTime() {
      let exitDateAndTime = this.selectedEndDate + " " + this.zoneDetails?.customizedEndTime

      this.odvTime = new Date(exitDateAndTime)
      this.dateTimeChange()

    },
    setDefaultDate() {
      this.odvTime = this.zoneDetails.timezone
        ?
        dateToTimeZoneDate(new Date(),
          this.zoneDetails.timezone)
        : new Date()
      this.minDate = this.zoneDetails.timezone
        ?
        dateToTimeZoneDate(new Date(),
          this.zoneDetails.timezone)
        : new Date()

      this.minDate = format(this.minDate, 'yyyy-MM-dd');
      //calculate max parkign time in seconds
      let maxParkingTime =
        this.zoneDetails != null
          ? Number(this.zoneDetails?.maxParkingTime?.days) *
          24 *
          60 *
          60 +
          Number(this.zoneDetails?.maxParkingTime?.hours) * 60 * 60 +
          Number(this.zoneDetails?.maxParkingTime?.minutes) * 60
          : null;
      let currentDateTime = new Date().getTime();

      //get max date in seconds based on max parking time
      let maxZoneDate =
        maxParkingTime != null ? currentDateTime + maxParkingTime * 1000 : null;
      //get max date with time and set it to maxDateTime
      this.maxDate = this.zoneDetails.timezone
        ? format(
          dateToTimeZoneDate(
            new Date(maxZoneDate),
            this.zoneDetails.timezone
          ),
          "yyyy-MM-dd"
        )
        : format(new Date(), "yyyy-MM-dd");
      this.zoneDetails?.timePickerConfiguration == '0' ? this.dateTimeChange() : ""
    },
    openSphereTimePicker() {
      EventBus.$emit('open-timepicker')
      this.sheet = true
    },

    async eventRateDateChange(event) {
      let selectedEvent = this.mySpotDetails.find((a) => a.eventName == event)
      this.selectedEvent = selectedEvent
      try {
        //format(new Date(this.checkinDate), "yyyy-MM-dd'T'HH:mm:ss");
        // this.setTimePicker();
        if (selectedEvent != null && selectedEvent != undefined && selectedEvent != '') {
          this.startDate = this.zoneDetails.timezone
            ? format(
              dateToTimeZoneDate(
                new Date(),
                this.zoneDetails.timezone
              ),
              "yyyy-MM-dd'T'HH:mm:ss"
            )
            : format(new Date(), "yyyy-MM-dd'T'HH:mm:ss");
          let stopDateAndTime = selectedEvent.localEndDateTime !== null && selectedEvent.localEndDateTime !== undefined && selectedEvent.localEndDateTime !== "" ? selectedEvent.localEndDateTime.split(" ") : "";
          let stopTime = stopDateAndTime != null && stopDateAndTime != undefined && stopDateAndTime != null && stopDateAndTime.length != 0 ? stopDateAndTime[1] : ""
          let exitdate = selectedEvent.localEndDateTime !== null && selectedEvent.localEndDateTime !== undefined && selectedEvent.localEndDateTime !== "" && stopDateAndTime != null && stopDateAndTime != undefined && stopDateAndTime != null && stopDateAndTime.length != 0 ? stopDateAndTime[0] : "";
          let stopDate = exitdate + 'T' + stopTime;
          let data = {
            startAt: this.startDate,
            stopAt: stopDate,
            zcode: this.zoneDetails.zcode,
            eventId: this.selectedEvent?.eventId || ""
          };
          this.stopAt = new Date(stopDate)
          this.addPaymentBtnLoading = true;
          var getRate = await APIHelper("POST", "/api/v2/getRate", data);
          //condition to check if the date time selected is lesser than current time
          if (!getRate?.data?.status) {
            this.disableAddPaymentBtn = true;
            this.dateDialog = false;
            this.alertDialog = true;
            this.alertMsg = getRate?.data?.message || "Error in getting rate details";

          }
          else {
            this.disableAddPaymentBtn = false;
            this.dateDialog = false;
            this.odvTime = new Date(selectedEvent.localEndDateTime)
          }
          this.estimate = Object.assign({}, getRate?.data?.data);
          this.selectedOption = 'event'
          let exitDateTime = selectedEvent.localEndDateTime
          let entryDateTime = this.zoneDetails.timezone
            ? format(
              dateToTimeZoneDate(
                new Date(),
                this.zoneDetails.timezone
              ),
              "yyyy-MM-dd HH:mm:ss"
            )
            : format(new Date(), "yyyy-MM-dd HH:mm:ss");
          let commitData = {
            plate: this.licencePlate,
            plateRegion: {
              country: this.plateRegion?.country,
              state: this.plateRegion?.state,
            },
            contact: this.contactObj.formattedNumber,
            entryTime: entryDateTime,
            exitTime: exitDateTime,
            estimate: this.estimate,
            eventId: this.selectedEvent?.eventId || ''
          };
          this.$store.commit("SET_ONDEMAND_USERDETAILS", commitData);
          // estimatedVal != "" &&
          // estimatedVal != null &&
          // estimatedVal != undefined
          //   ? Number(estimatedVal).toFixed(2)
          //   : estimatedVal;
          this.addPaymentBtnLoading = false;
        }
      } catch (error) {

        this.addPaymentBtnLoading = false;
        console.log(error);
      }
    },
    eventModeLocalTime(time) {
      return time != null && time != undefined && time != "" ? format(new Date(time), `LLL dd , yyyy`) + " at " + format(new Date(time), `hh:mm a`) : "";

    },
    async findUser() {
      try {
        var findUser = await APIHelper("POST", "/api/v1/findUserWithCC", {
          zid: this.zoneDetails?.zid,
          contact: this.contactObj?.formattedNumber
        });
        if (findUser != null && findUser != undefined && findUser != "" && findUser?.data?.status) {
          this.userCardNumber = findUser?.data?.userSavedCardDetail?.paymentCardNo
        }
        else {
          this.userCardNumber = ''
        }
      } catch (error) {
        console.log(error)
      }
    },
    async createOdTransaction() {
      //  let phoneNumber = this.contactObj.formattedNumber.substring(1, this.contactObj.formattedNumber.length)
      this.odSessionLoading = true;
      let exitDateTime = format(
        new Date(this.odvTime),
        "yyyy-MM-dd HH:mm:ss"
      );
      let entryDateTime = this.zoneDetails.timezone
        ? format(
          dateToTimeZoneDate(
            new Date(),
            this.zoneDetails.timezone
          ),
          "yyyy-MM-dd HH:mm:ss"
        )
        : format(new Date(), "yyyy-MM-dd HH:mm:ss");
      var findUser = await APIHelper("POST", "/api/v1/findUserWithCC", {
        zid: this.zoneDetails?.zid,
        contact: this.contactObj?.formattedNumber
      });
      let details = await APIHelper("POST", "/api/v1/prepaid/openTransaction", {
        vehicleNo: this.licencePlate,
        plateRegion: {
          country: this.plateRegion?.country,
          state: this.plateRegion?.state,
        },
        contact: this.contactObj.formattedNumber,
        entryTime: entryDateTime,
        exitTime: exitDateTime,
        zcode: this.zoneDetails?.zcode,
      });
      if (details?.data?.status) {
        if (findUser != null && findUser != undefined && findUser != "" && findUser?.data?.status) {
          try {
            let postData = {
              userDetail: {
                plate: this.licencePlate,
                plateRegion: {
                  country: this.plateRegion?.country,
                  state: this.plateRegion?.state,
                },
                contact: this.contactObj.formattedNumber,
                exitTime: exitDateTime,
                eventType: "OD",
                eventId: this.selectedEvent?.eventId || ''
              },
              cardDetail: {
                userDevice: "2",
                orderType: "3",
                entry: [
                  {
                    locationCode: this.zoneDetails?.zcode,
                    startAt: entryDateTime,
                    quantity: "1"
                  }
                ],
                payment: {
                  email: findUser?.data?.userSavedCardDetail?.email
                },
              },
              userSavedCardDetail: {
                customerId: findUser?.data?.userSavedCardDetail?.customerId,
                accountId: findUser?.data?.userSavedCardDetail?.accountId,
                paymentCardId: findUser?.data?.userSavedCardDetail?.paymentCardId,
                email: findUser?.data?.userSavedCardDetail?.email,
                paymentCardType: findUser?.data?.userSavedCardDetail?.paymentCardType,
                paymentCardNo: findUser?.data?.userSavedCardDetail?.paymentCardNo,
                saveCardUserType: findUser?.data?.userSavedCardDetail?.saveCardUserType
              },
              mid: this.zoneDetails.mid,
              source: "web"
            };
            var odAddCard = await APIHelper(
              "POST",
              "/api/v1/prepaid/addCard",
              postData
            );
            if (odAddCard?.data?.status == true) {
              let url = window.location.origin + '/g/' + odAddCard?.data?.data?.bid;
              window.location.replace(url);
            } else if (odAddCard?.data?.status == false) {
              this.alertDialog = true;
              this.alertMsg = odAddCard?.data?.message;
            }
            this.odSessionLoading = false;
          } catch (error) {
            this.odSessionLoading = false;
            console.log(error);
          }
        }
        else {
          this.odSessionLoading = false;
          this.alertMsg = findUser?.data?.message ? findUser?.data?.message : "";
          this.alertDialog = true;
        }
      }
      else {
        this.odSessionLoading = false;
        this.alertMsg = details?.data?.message ? details?.data?.message : "";
        this.alertDialog = true;
        if (details?.data?.message == 'Booking Already Exists') {
          //url segment is app mixin computed property
          let url = window.location.origin + '/g/' + details?.data?.data?.refno
          window.location.replace(url);

        }
      }
    },

    round(time) {
      let exitdate = time !== null && time !== '' ? format(time, 'yyyy/MM/dd') : new Date();

      let selectedMins = time !== null && time !== '' ? format(time, 'mm') : new Date();
      let selectedHours = time !== null && time !== '' ? format(time, 'hh') : new Date();
      let selectedAMPM = time !== null && time !== '' ? format(time, 'aaa') : new Date();
      if (exitdate != null && selectedMins != '15' && selectedMins != '30' && selectedMins != '45' && selectedMins != '00' && selectedMins != null && selectedMins != "" && selectedHours != null && selectedHours != "" && selectedAMPM != null && selectedAMPM != "") {
        let intervalMilliseconds = 900000
        let datetime = new Date(exitdate + " " + selectedHours + ":" + selectedMins + " " + selectedAMPM)
        let interval = datetime.getTime() % intervalMilliseconds;
        let delta = interval === 0 ? 0 : datetime.getTime() - interval;
        delta += intervalMilliseconds;
        // this.exitTimeSelected = Object.assign(this.exitTimeSelected, { hh: format(new Date(delta), 'hh'), mm: format(new Date(delta), 'mm'), a: format(new Date(delta), 'a'), })
        return interval !== 0 ? new Date(delta) : datetime;
      }
    },

    openDatePicker() {
      this.dateDialog = true;

    },
    /** @methods to find the estimate rate 
     @params startDate , stopDate,zcode, type , env_overRide
     */

    async dateTimeChange() {

      try {
        this.$store.commit("SET_LOADING_STATUS", {
          property: "APP",
          pending: true,
        });
        //format(new Date(this.checkinDate), "yyyy-MM-dd'T'HH:mm:ss");
        // this.setTimePicker();
        this.startDate = this.zoneDetails.timezone
          ? format(
            dateToTimeZoneDate(
              new Date(),
              this.zoneDetails.timezone
            ),
            "yyyy-MM-dd'T'HH:mm:ss"
          )
          : format(new Date(), "yyyy-MM-dd'T'HH:mm:ss");
        let stopTime = Object.hasOwnProperty.call(this.zoneDetails, 'timePickerConfiguration') && this.zoneDetails.timePickerConfiguration == '0' ? (this.zoneDetails?.customizedEndTime ? this.zoneDetails?.customizedEndTime : '23:59:00') : format(this.odvTime, 'HH:mm:ss'); //?.hh+":"+this.exitTimeSelected.mm+":00"//format(this.checkoutDate, "yyyy-MM-dd'T'HH:mm:ss")
        let exitdate = this.odvTime !== null && this.odvTime !== undefined && this.odvTime !== "" ? format(this.zoneDetails.timePickerConfiguration == '0' && this.customEndTimeMinDate ? addDays(this.odvTime, 1) : this.odvTime, 'yyyy-MM-dd') : "";
        let stopDate = exitdate + 'T' + stopTime;
        let data = {
          startAt: this.startDate,
          stopAt: stopDate,
          zcode: this.zoneDetails.zcode,
        };
        this.stopAt = new Date(stopDate)
        this.addPaymentBtnLoading = true;
        var getRate = await APIHelper("POST", "/api/v2/getRate", data);
        this.$store.commit("SET_LOADING_STATUS", {
          property: "APP",
          pending: false,
        });
        //condition to check if the date time selected is lesser than current time
        if (!getRate?.data?.status) {
          this.$store.commit("SET_LOADING_STATUS", {
            property: "APP",
            pending: false,
          });
          this.disableAddPaymentBtn = true;
          this.alertDialog = true;
          this.alertMsg = getRate?.data?.message || "Error in getting rate details";
          EventBus.$emit('datetime-set');
          this.dateDialog = false;

        }
        else {
          this.$store.commit("SET_LOADING_STATUS", {
            property: "APP",
            pending: false,
          });
          this.disableAddPaymentBtn = false;
          this.dateDialog = false;
          this.zoneEndTimeLabel = `You are eligible to stay till ${format(this.stopAt, 'MM-dd-yyyy hh:mm a')}`
          this.openTimePicker = false;
          EventBus.$emit('datetime-set');
        }
        this.$store.commit("SET_LOADING_STATUS", {
          property: "APP",
          pending: false,
        });
        this.estimate = Object.assign({}, getRate?.data?.data);
        this.selectedEvent = {}
        // estimatedVal != "" &&
        // estimatedVal != null &&
        // estimatedVal != undefined
        //   ? Number(estimatedVal).toFixed(2)
        //   : estimatedVal;
        this.addPaymentBtnLoading = false;
        this.selectedOption = 'date'
      } catch (error) {
        this.$store.commit("SET_LOADING_STATUS", {
          property: "APP",
          pending: false,
        });
        this.addPaymentBtnLoading = false;
        console.log(error);
      }
    },
    async addPayment() {
      let odDetails = localStorage.getItem('odDetails');
      odDetails = odDetails ? JSON.parse(odDetails) : {};
      localStorage.setItem("odDetails", JSON.stringify({
        contact: this.contactObj, licencePlate: this.licencePlate, plateRegion: {
          country: this.plateRegion?.country,
          state: this.plateRegion?.state,
        }, otpVerified: odDetails.otpVerified
      }));
      var exitDateTime = ""
      if (this.selectedOption == 'event') {
        var stopDateAndTime = this.selectedEvent.localEndDateTime !== null && this.selectedEvent.localEndDateTime !== undefined && this.selectedEvent.localEndDateTime !== "" ? this.selectedEvent.localEndDateTime.split(" ") : "";
        var stopTime = stopDateAndTime != null && stopDateAndTime != undefined && stopDateAndTime != null && stopDateAndTime.length != 0 ? format24TimeToAMPM(stopDateAndTime[1]) : ""
        var exitdate = this.selectedEvent.localEndDateTime !== null && this.selectedEvent.localEndDateTime !== undefined && this.selectedEvent.localEndDateTime !== "" && stopDateAndTime != null && stopDateAndTime != undefined && stopDateAndTime != null && stopDateAndTime.length != 0 ? stopDateAndTime[0] : "";
        exitDateTime = exitdate + ' ' + stopTime;
      }
      else {
        exitDateTime = this.odvTime != null || this.odvTime !== undefined || this.odvTime != "" ?
          format(this.zoneDetails.timePickerConfiguration == '0' && this.customEndTimeMinDate ? addDays(this.odvTime, 1) : this.odvTime, 'yyyy-MM-dd')
          +
          " " +
          (this.zoneDetails?.timePickerConfiguration == '0' ? format24TimeToAMPM(this.zoneDetails?.customizedEndTime) : format(this.odvTime, 'hh:mm a')) : ""
      }
      let entryDateTime = this.zoneDetails.timezone
        ? format(
          dateToTimeZoneDate(new Date(),
            this.zoneDetails.timezone
          ),
          "yyyy-MM-dd hh:mm a"
        )
        : format(new Date(), "yyyy-MM-dd HH:mm a");
      let commitData = {
        plate: this.licencePlate,
        plateRegion: {
          country: this.plateRegion?.country,
          state: this.plateRegion?.state,
        },
        contact: this.contactObj.formattedNumber,
        entryTime: entryDateTime,
        exitTime: exitDateTime,
        estimate: this.estimate,
        eventId: this.selectedEvent?.eventId || ''
      };
      switch (this.templateFlags) {
        case 'ondemand-flag': {
          let details = await APIHelper("POST", "/api/v1/prepaid/openTransaction", {
            vehicleNo: this.licencePlate,
            plateRegion: {
              country: this.plateRegion?.country,
              state: this.plateRegion?.state,
            },
            contact: this.contactObj.formattedNumber,
            entryTime: entryDateTime,
            exitTime: exitDateTime,
            zcode: this.zoneDetails?.zcode,

          });
          if (details?.data?.status) {
            this.$store.commit("SET_ONDEMAND_USERDETAILS", commitData);
            this.$router.replace({ path: "/ondemand/makepayment" });
          }
          else {
            if (details?.data?.message == 'Booking Already Exists') {
              //url segment is app mixin computed property
              let url = window.location.origin + '/g/' + details?.data?.data?.refno
              window.location.replace(url);

            }
            this.alertMsg = details?.data?.message ? details?.data?.message : "";
            this.alertDialog = true;
          }
          break;
        }
        case 'atlantic-flag': {
          let details = await APIHelper("POST", "/api/v1/prepaid/openTransaction", {
            vehicleNo: this.licencePlate,
            plateRegion: {
              country: this.plateRegion?.country,
              state: this.plateRegion?.state,
            },
            contact: this.contactObj.formattedNumber,
            entryTime: entryDateTime,
            exitTime: exitDateTime,
            zcode: this.zoneDetails?.zcode,
            mid: this.zoneDetails?.mid,
            eventType: 'PaidHostPass'
          });

          if (details?.data?.status) {
            this.$store.commit("SET_CASINO_USERDETAILS", commitData);
            this.$router.replace({ path: "/prepaidhost/makepayment" });
          } else {
            if (details?.data?.message == "Booking Already Exists") {
              //url segment is app mixin computed property
              let url = window.location.origin + '/g/' + details?.data?.data?.bid;
              window.location.replace(url)
            }
            this.alertMsg = details?.data?.message ? details?.data?.message : "";

            this.alertDialog = true;
          }
        }
      }

    },

    uppercase() {
      if (rules.alphaNumeric(this.licencePlate) === "Must be alphanumeric") {
        this.licencePlate = this.licencePlate.replace(/[^\w\s]/gi, '')
      }
      this.licencePlate = this.licencePlate.trim()
      this.licencePlate = this.licencePlate.toUpperCase();
    },
    /**
     * @method getZoneDetails fetch the zone details and commit SET_ZONE_DETAILS mutation.
     */
    async getZoneDetails(searchParamKey, searchParamValue) {
      this.loading = true
      try {
        var zoneDetails = await APIHelper(
          "GET",
          "/api/v1/user/config" + searchParamKey + "=" + searchParamValue
        );
        this.loading = false
        var getMySpotEvent = await APIHelper("POST", "/api/v1/getMySpotEvent", { zcode: zoneDetails?.data?.zone?.zcode });
        const updatedEvents = await this.updateEventPrices(
          getMySpotEvent?.data?.events
        );
        this.$store.commit("SET_ZONE_DETAILS", zoneDetails.data?.zone);
        this.$store.commit("SET_MYSPOT_DETAILS", updatedEvents);
        await this.findUser()
      } catch (error) {
        this.loading = false
        console.log(error);
      }
    },
    async updateEventPrices(events) {
      var self = this;
      let startDate = self.zoneDetails.timezone
        ? format(
          dateToTimeZoneDate(
            new Date(),
            self.zoneDetails.timezone
          ),
          "yyyy-MM-dd'T'HH:mm:ss"
        )
        : format(new Date(), "yyyy-MM-dd'T'HH:mm:ss");
      const fetchPromises = events.map((event) =>
        axios.post(process.env.VUE_APP_API_URL + "/api/v2/getRate", {
          eventId: event.eventId,
          startAt: startDate,
          stopAt: event.localEndDateTime.split(" ").join("T"),
          zcode: self.zoneDetails.zcode,
        })
      );
      try {
        const responses = await axios.all(fetchPromises);
        const results = responses.map((response) => response.data);
        const updatedEventPrices = results.map(a => a?.data)
        let updatedEvents = events;
        for (let i = 0; i < updatedEvents.length; i++) {
          let grandTotal = updatedEventPrices.find(p => p?.eventId == updatedEvents[i]?.eventId)?.grandTotal || updatedEvents[i]?.price;
          Object.assign(updatedEvents[i], { price: grandTotal })
        }
        return updatedEvents;
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    },
    onContactInput(contactDetails) {
      this.isContactValid = contactDetails?.isValid;
      this.contactObj = Object.assign({}, contactDetails);
    },
  },

  computed: {
    isPreAuthEnabled() {
      return Object.hasOwnProperty.call(this.zoneDetails, 'preAuth') && Object.hasOwnProperty.call(this.zoneDetails?.preAuth, 'enabled') && this.zoneDetails?.preAuth?.enabled == '1'
    },
    paymentCols() {
      return (this.iosDevice && this.zoneDetails?.paymentServices?.apple_pay == '0') || (!this.iosDevice && this.zoneDetails?.paymentServices?.google_pay == '0')
    },
    customEndTimeMinDate() {
      let currentTime = this.zoneDetails.timezone
        ?
        dateToTimeZoneDate(new Date(),
          this.zoneDetails.timezone
        )
        : new Date();
      let givenTime = this.odvTime
      let timeVal = this.zoneDetails.customizedEndTime != null && this.zoneDetails.customizedEndTime != undefined ? this.zoneDetails.customizedEndTime.split(':') : ['23', '59', '59']
      givenTime.setHours(timeVal[0], timeVal[1], timeVal[2])
      let check = false;
      if (givenTime <= currentTime) {
        check = true;
        return check
      }
      else {
        check = false
        return check
      }
    },
    disableAPGP() {
      let entryDateTime = this.zoneDetails.timezone
        ?
        dateToTimeZoneDate(new Date(),
          this.zoneDetails.timezone
        )
        : new Date();
      return differenceInMinutes(this.stopAt != null && this.stopAt != '' && this.stopAt != undefined ? this.stopAt : this.odvTime, entryDateTime) < 1425
    },
    eventName() {
      return this.mySpotDetails[0] != null && this.mySpotDetails[0] != undefined && this.mySpotDetails[0] != "" ? this.mySpotDetails[0].eventName : "";
    },
    defaultCountryCode() {
      return Object.hasOwnProperty.call(this.contactObj, 'countryCode') && this.contactObj.countryCode != null && this.contactObj.countryCode != undefined && this.contactObj.countryCode != "" ? this.contactObj.countryCode : this.contactOptions?.defaultCountry
    },
    checkOTPVerified() {
      let odDetails = localStorage.getItem('odDetails');
      odDetails = odDetails ? JSON.parse(odDetails) : {};
      return odDetails?.otpVerified
    },
    ...mapGetters({
      odDetails: "getterOdDetails",
      casinoDetails: 'getterCasinoDetails',
      OnDemandZoneDetails: "getterOnDemandZoneDetails",
      zoneDetails: "getterZoneDetails",
      templateFlags: 'getterTemplateFlags',
      mySpotDetails: 'getterMySpotDetails',
    }),
    formattedTime() {
      return this.odvTime !== null && this.odvTime !== undefined && this.odvTime !== "" ? (this.zoneDetails?.timePickerConfiguration == '0' ? format(this.odvTime, 'yyyy-MM-dd') : format(this.odvTime, 'yyyy-MM-dd hh:mm aa')) : ""
    },
    infoStepperFlag() {
      return this.contactObj == null ||
        !this.contactObj.isValid ||
        this.contactObj.phoneNumber == "" ||
        this.contactObj.phoneNumber == null ||
        this.licencePlate === "" ||
        this.licencePlate === null ||
        this.plateRegion?.country === "" ||
        this.plateRegion?.country === null ||
        this.plateRegion?.state === "" ||
        this.plateRegion?.state === null ? false : true
    },
    eventFlag() {
      return this.mySpotDetails != null && this.mySpotDetails != undefined && this.mySpotDetails != '' && this.mySpotDetails.length > 0 ? false : true
    },
    timeStepperFlag() {
      return this.odvTime === null || this.disableAddPaymentBtn || this.estimate === null || this.estimate === '' ||
        this.odvTime === "" || this.odvTime === undefined ? false : true;
    },
    paymentStepperFlag() {
      return this.estimate === null || this.estimate === '' || this.estimate.hasOwnProperty.call('grandTotal') || this.estimate.hasOwnProperty.call('quotedRegularPrice') || this.estimate.hasOwnProperty.call('totalFee') || this.estimate.hasOwnProperty.call('totalTax') ? false : true
    },
    computedDateFormatted() {
      return this.odvTime != null && this.odvTime != "" && this.odvTime != undefined ? format(this.odvTime, 'MM/dd/yyyy') : ""
    },
    disableSubmitBtn() {
      let disabled =
        this.contactObj == null ||
        !this.contactObj.isValid ||
        this.contactObj.phoneNumber == "" ||
        this.contactObj.phoneNumber == null ||
        this.licencePlate === "" ||
        this.licencePlate === null ||
        this.plateRegion?.country === "" ||
        this.plateRegion?.country === null ||
        this.plateRegion?.state === "" ||
        this.plateRegion?.state === null ||
        this.odvTime === null || this.disableAddPaymentBtn || this.estimate === null || this.estimate === ''
      this.odvTime === "" || this.odvTime == undefined;
      disabled =
        this.licencePlate != "" && this.licencePlate != null
          ? disabled || /^[a-z0-9]+$/i.test(this.licencePlate) === false
          : disabled;
      let selectedMins = this.odvTime !== null && this.odvTime !== undefined && this.odvTime !== "" ? format(this.odvTime, 'mm') : new Date();
      let selectedHours = this.odvTime !== null && this.odvTime !== undefined && this.odvTime !== "" ? format(this.odvTime, 'hh') : new Date();
      let selectedAMPM = this.odvTime !== null && this.odvTime !== undefined && this.odvTime !== "" ? format(this.odvTime, 'aaa') : new Date();
      disabled =
        disabled ||
        selectedMins == null ||
        selectedMins === "" ||
        selectedMins == undefined ||
        selectedHours == null ||
        selectedHours === "" ||
        selectedHours == undefined || selectedAMPM == null ||
        selectedAMPM === "" ||
        selectedAMPM == undefined || this.odvTime === null || this.odvTime === "" || this.odvTime === undefined
      return disabled;
    },
  },
};
</script>

<style lang="less">
.information {
  background-color: #edf1f4;
}

.booking_header {
  position: absolute;
  bottom: 30px;
  left: 16px;
  font-size: 18px;
  font-weight: bolder;
}

.add_licence_plate_btn {
  width: 100%;
  background-color: #f2555c !important;
  font-size: 18px !important;
  font-weight: 700 !important;
  letter-spacing: normal !important;
  height: 50px !important;
}

.country-selector.md.has-value .country-selector__input {
  border: none;
  border-bottom: 1px solid rgb(118, 118, 118);
}

.input-tel.md .input-tel__input {
  border: none;
  border-bottom: 1px solid rgb(118, 118, 118);
  margin-left: 2px;
}

.input-tel.is-focused.has-error .input-tel__input {
  box-shadow: none !important;
}

.input-tel.is-focused.is-valid .input-tel__input {
  box-shadow: none !important;
}


hr {
  border: 1px solid #ffbc1f;
}

.p-inputtext {
  background: transparent;
  border: none;
  border-bottom: 1px solid grey;
  text-align: left;
}

.no-border-select .v-select__selections {
  border-bottom: none !important;
  box-shadow: none !important;
}

.v-select.v-input--is-disabled {
  border-bottom-style: solid !important;
}

// .vue__time-picker input.display-time{
//   border-bottom:1px solid #767676;
//     border-top:0px;
//     border-right:0px;
//     border-left:0px;
// }</style>
